html, body { width:100%; min-height:100vh; height:100%; font-size:16px; background-color:white; font-family:'Roboto', Arial, Helvetica, sans-serif;}
input, select, button { color:#032663; font-size:inherit; font-weight:inherit; letter-spacing:inherit; font-family:inherit;}

@media only screen and (max-width: 1700px) {
    html, body { font-size:15px;}
    @media only screen and (max-width: 1400px) {
        html, body { font-size:14px;}
        @media only screen and (max-width: 1300px) {
            html, body { font-size:13px;}
            @media only screen and (max-width: 1200px) {
                html, body { font-size:12px;}
                @media only screen and (max-width: 850px) {
                    html, body { font-size:13px;}
                }
            }
        }
    }
}
@media only screen and (min-width: 2000px) {
    html, body { font-size:17px;}
    @media only screen and (min-width: 2200px) {
        html, body { font-size:18px;}
        @media only screen and (min-width: 2400px) {
            html, body { font-size:19px;}
            @media only screen and (min-width: 2600px) {
                html, body { font-size:20px;}
                @media only screen and (min-width: 2800px) {
                    html, body { font-size:22px;}
                    @media only screen and (min-width: 3000px) {
                        html, body { font-size:24px;}
                        @media only screen and (min-width: 3200px) {
                            html, body { font-size:26px;}
                            @media only screen and (min-width: 3400px) {
                                html, body { font-size:28px;}
                                @media only screen and (min-width: 3600px) {
                                    html, body { font-size:32px;}
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Interactions */
.cursor-pointer { cursor:pointer;}
.cursor-none { cursor:none;}
.no-decoration { text-decoration:none; outline:none; -webkit-tap-highlight-color:rgba(0,0,0,0);}

/* Base */
.overflow { overflow:hidden;}
.width-overflow { overflow:hidden; width:100%;}
* { position:relative;}

/* Base mobile */
.only-mobile { position:absolute; width:0; height:0; overflow:hidden; opacity:0;}
@media only screen and (max-width: 850px) {
    .hide-mobile { display:block; width:0 !important; height:0 !important; padding:0 !important; min-width:0 !important; min-height:0 !important; overflow:hidden; opacity:0;}
    .only-mobile { position:relative; width:auto; height:auto; overflow:initial; opacity:1;}
    @media only screen and (max-width: 500px) {
        .hide-small-mobile { display:block; width:0 !important; height:0 !important; padding:0 !important; overflow:hidden; opacity:0;}
        .only-small-mobile { width:auto; height:auto; overflow:initial; opacity:1;}
    }
}

/* Contenus centrés */
.center-holder { text-align:center;}
.right-holder { text-align:right;}
.left-holder { text-align:left;}
.centered { margin: 0 auto;}
.centered-content-120 { width:120em; max-width:calc(100% - 6em); max-width:-webkit-calc(100% - 6em); margin: 0 auto;}
.centered-content-75 { width:75em; max-width:calc(100% - 6em); max-width:-webkit-calc(100% - 6em); margin: 0 auto;}
.centered-content-55 { width:55em; max-width:calc(100% - 6em); max-width:-webkit-calc(100% - 6em); margin: 0 auto;}
.centered-content-40 { width:40em; max-width:calc(100% - 4em); max-width:-webkit-calc(100% - 4em); margin: 0 auto;}
.centered-inner-8 { max-width:calc(100% - 16em); max-width:-webkit-calc(100% - 16em); margin: 0 auto;}
.centered-inner-4 { max-width:calc(100% - 8em); max-width:-webkit-calc(100% - 8em); margin: 0 auto;}
@media only screen and (max-width: 850px) {
    .center-holder-mobile { text-align:center;}
    .centered-content-120 { max-width:calc(100% - 4em); max-width:-webkit-calc(100% - 4em);}
    .centered-content-75 { width:29em; max-width:calc(100% - 1em); max-width:-webkit-calc(100% - 1em);}
    .centered-content-55 { max-width:calc(100% - 1em); max-width:-webkit-calc(100% - 1em);}
    .centered-content-40 { max-width:calc(100% - 1em); max-width:-webkit-calc(100% - 1em);}
    .centered-inner-8 { max-width:calc(100% - 2em); max-width:-webkit-calc(100% - 2em);}
    .centered-inner-4 { max-width:calc(100% - 2em); max-width:-webkit-calc(100% - 2em);}
}

/* Positionnement */
.rel { position:relative;}
.fixed { position:fixed; top:0; left:0;}
.abs { position:absolute; top:0; left:0;}
.abs.top-0-5em { top:0.5em;}
.abs.top--0-5em { top:-0.5em;}
.abs.top-10 { top:10%; bottom:auto;}
.abs.top-15 { top:15%; bottom:auto;}
.abs.top-20 { top:20%; bottom:auto;}
.abs.top-50 { top:50%; bottom:auto;}
.abs.top-100 { top:100%; bottom:auto;}
.abs.bot-0-5em { bottom:0.5em;}
.abs.bot--0-5em { bottom:-0.5em;}
.abs.bot,
.fixed.bot { top:auto; bottom:0;}
.abs.full-fit { top:0; bottom:0; left:0; right:0;}
.abs.bot-100 { top:auto; bottom:100%;}
.abs.left--3em { left:-3em;}
.abs.left-0 { left:0; right:auto;}
.abs.left-10 { left:10%; right:auto;}
.abs.left-15 { left:15%; right:auto;}
.abs.left-20 { left:20%; right:auto;}
.abs.left-third { left:33.33%; left:calc(100% / 3); left:-webkit-calc(100% / 3); right:auto;}
.abs.left-50 { left:50%; right:auto;}
.abs.left-100 { left:100%; right:auto;}
.abs.right,
.fixed.right { left:auto; right:0;}
.abs.right-15 { left:auto; right:15%;}
.abs.right-100 { left:auto; right:100%;}
.abs.top-bot { top:0; bottom:0;}
.abs.left-right { left:0; right:0;}
.line-height-1 { line-height:1em;}
.line-height-1-25 { line-height:1.25em;}
.line-height-2 { line-height:2em;}
.flexbox { display:flex;}
.flexbox.column { flex-direction:column;}
.flexbox.center { align-items:center; justify-content:center;}
.flexbox.right { justify-content:right;}
.flexbox.gap-1 { gap:1em;}
.flexbox.gap-1-5 { gap:1.5em;}
.flexbox.gap-h-3 { gap:0 3em;}
.flexbox.gap-h-2 { gap:0 2em;}
.flexbox.gap-h-1-5 { gap:0 1.5em;}
.flexbox.gap-h-1 { gap:0 1em;}
.flexbox.gap-h-0-75 { gap:0 0.75em;}
.flexbox.gap-h-0-5 { gap:0 0.5em;}
.flexbox.gap-v-0-25 { gap:0.25em 0;}
.flexbox.gap-v-1 { gap:1em 0;}
.flexbox.gap-v-2 { gap:2em 0;}
.flexbox .flex { flex:1;}
.flexbox .flex-2 { flex:2;}
.flexbox .align-right { align-self:right;}
.flexbox .order-first { order:-1;}
.flexbox .order-last { order:99;}
.clear { display:block; clear:both;}
.clear-after:after { display:block; content:""; clear:both;}
.float { float:left;}
.float.right { float:right;}
.middle-holder { display:table; height:100%; width:100%;}
.middle-holder .middle { vertical-align:middle; width:100%; display:table-cell;}
.svg-v-align-top svg { vertical-align:top;}
.svg-v-align-bot svg { vertical-align:bottom;}
.v-align-bot { vertical-align:bottom;}
.v-align-top { vertical-align:top;}
.v-align-middle { vertical-align:middle;}
.v-align-middle-childs > * { vertical-align:middle;}
.bg-position-top { object-position:center top;}
.bg-position-center-top { object-position:center top; background-position:center top;}
.bg-position-center { object-position:center; background-position:center center;}
.bg-cover { object-fit:cover; background-size:cover;}
.bg-contain { object-fit:contain; background-size:contain;}
.bg-no-repeat { background-repeat:no-repeat;}
@media only screen and (max-width: 850px) {
    .rel-mobile,
    .open .open-parent-rel-mobile { position:relative; left:auto !important; right:auto !important; top:auto !important; bottom:auto !important;}
    .abs-mobile { position:absolute;}
    .abs-mobile.bot-0-mobile,
    .abs.bot-0-mobile { bottom:0; top:auto;}
    .abs-mobile.top-0-mobile,
    .abs.top-0-mobile { top:0; bottom:auto;}
    .abs-mobile.top-100-mobile,
    .abs.top-100-mobile { top:100%; bottom:auto;}
    .abs-mobile.left-0-mobile,
    .abs.left-0-mobile { left:0; right:auto;}
    .abs-mobile.right-0-mobile,
    .abs.right-0-mobile { right:0; left:auto;}
    .abs-mobile.left-auto-mobile,
    .abs.left-auto-mobile { left:auto; bottom:auto;}
    .float-none-mobile { float:none !important;}
    .flexbox-mobile { display:flex;}
    .flexbox-mobile.column { flex-direction:column;}
    .flexbox-mobile.center { align-items:center; justify-content:center;}
    .flexbox.wrap-mobile,
    .flexbox-mobile.wrap-mobile { flex-wrap:wrap;}
    .flexbox.column-mobile { flex-direction:column;}
    .flexbox.gap-0-5-mobile,
    .flexbox-mobile.gap-0-5-mobile { gap:0.5em;}
    .flexbox.gap-1-mobile { gap:1em;}
    .flexbox.gap-h-1-mobile { gap:0 1em;}
    .flexbox.gap-1-5-mobile { gap:1.5em;}
    .flexbox.gap-2-mobile { gap:2em;}
    .flexbox.gap-h-2-mobile { gap:0 2em;}
    .nowrap-mobile { white-space:nowrap;}
    .flexbox-mobile .break-mobile,
    .flexbox .break-mobile { flex-basis:100%;}
    .flexbox.left-mobile { align-items:normal;}
    .flexbox .noflex-mobile { flex:none;}
}

/* Z index */
.z-index-1 { z-index:1;}
.z-index-2 { z-index:2;}
.z-index-3 { z-index:3;}
.z-index-4 { z-index:4;}
.z-index-5 { z-index:5;}
.z-index-6 { z-index:6;}
.z-index-7 { z-index:7;}
.z-fg { z-index:10;}
.no-events { pointer-events:none;}
.active-events.active,
.open .open-parent-events,
.spe-parent.open .open-spe-parent-events { pointer-events:all;}
.no-select { user-select:none; -webkit-user-select:none; -ms-user-select:none; -webkit-tap-highlight-color:rgba(0,0,0,0);}
@media only screen and (max-width: 850px) {
    .no-events-mobile { pointer-events:none;}
    .open .open-parent-events-mobile,
    .open-events-mobile.open { pointer-events:all;}
}

/* Dimensionnement */
.block { display:block;}
.inline-block { display:inline-block;}
.border-box { box-sizing:border-box;}
.height-1px { height:1px;}
.height-2px { height:2px;}
.height-3px { height:3px;}
.height-50 { height:50%;}
.height-100 { height:100%;}
.height-0-25em { height:0.25em;}
.height-0-35em { height:0.35em;}
.height-0-5em { height:0.5em;}
.height-0-625em { height:0.625em;}
.height-0-75em { height:0.75em;}
.height-checkbox { height:0.8125em;}
.height-1em { height:1em;}
.height-1-25em { height:1.25em;}
.height-1-375em { height:1.375em;}
.height-1-5em { height:1.5em;}
.height-1-75em { height:1.75em;}
.height-2em { height:2em;}
.height-2-25em { height:2.25em;}
.height-2-375em { height:2.375em;}
.height-2-5em { height:2.5em;}
.height-3em { height:3em;}
.height-4em { height:4em;}
.height-4-5em { height:4.5em;}
.height-5em { height:5em;}
.height-7em { height:7em;}
.height-8em { height:8em;}
.height-10em { height:10em;}
.height-16em { height:16em;}
.height-60 { height:60%;}
.height-70 { height:70%;}
.height-80 { height:80%;}
.height-100vh { height:100vh;}
.min-height-3em { min-height:3em;}
.min-height-4em { min-height:4em;}
.min-height-26em { min-height:26em;}
.min-height-100vh { min-height:100vh;}
.max-height-30em { max-height:30em;}
.max-height-80vh { max-height:80vh;}
.width-third { width:33.33%; width:calc(100% / 3); width:-webkit-calc(100% / 3);}
.width-0 { width:0;}
.width-20 { width:20%;}
.width-25 { width:25%;}
.width-50 { width:50%;}
.width-55 { width:55%;}
.width-60 { width:60%;}
.width-100 { width:100%;}
.width-110 { width:110%;}
.max-width-50 { max-width:50%;}
.max-width-100 { max-width:100%;}
.width-1px { width:1px;}
.width-0-25em { width:0.25em;}
.width-0-3125em { width:0.3125em;}
.width-0-5em { width:0.5em;}
.width-checkbox { width:0.8125em;}
.width-1em { width:1em;}
.width-1-1em { width:1.1em;}
.width-1-25em { width:1.25em;}
.width-1-5em { width:1.5em;}
.width-2em { width:2em;}
.width-2-25em { width:2.25em;}
.width-2-35em { width:2.35em;}
.width-2-5em { width:2.5em;}
.width-3em { width:3em;}
.width-4em { width:4em;}
.width-4-5em { width:4.5em;}
.width-5em { width:5em;}
.width-6em { width:6em;}
.width-8em { width:8em;}
.width-10em { width:10em;}
.width-11em { width:11em;}
.width-13em { width:13em;}
.width-menu { width:15em;}
.width-15em { width:15em;}
.max-width-15em { max-width:15em;}
.width-20em { width:20em;}
.width-25em { width:25em;}
.width-login-col,
.width-31em { width:31em;}
.width-50em { width:50em;}
.width-90em { width:90em;}
.width-100em { width:100em;}
.width-500em { width:500em;}
.width-60 { width:60%;}
.width-70 { width:70%;}
.width-80 { width:80%;}
.view-size { min-width:55em; height:85%; overflow:auto;}
.transition-width { transition:width 0.25s; -webkit-transition:width 0.25s;}
.svg-fit-height, .svg-fit-width { line-height:0;}
.svg-fit-height svg { height:100%; vertical-align:middle;}
.svg-fit-width svg { width:100%; vertical-align:middle;}
.width-planning-left { width:22.5em;}
.height-planning-head { height:2em;}
@media only screen and (max-width: 850px) {
    .block-mobile { display:block;}
    .inline-block-mobile { display:inline-block;}
    .width-2em-mobile { width:2em;}
    .width-3em-mobile { width:3em;}
    .width-7em-mobile { width:7em;}
    .width-11em-mobile { width:11em;}
    .width-80em-mobile { width:80em;}
    .width-35em-mobile { width:35em;}
    .max-width-20em-mobile { max-width:20em;}
    .max-width-28em-mobile { max-width:28em;}
    .max-width-100-mobile { max-width:100%;}
    .width-100-mobile { width:100%;}
    .width-auto-mobile { width:auto;}
    .height-auto-mobile { height:auto;}
    .height-1px-mobile { height:1px;}
    .height-0-5em-mobile { height:0.5em;}
    .height-2em-mobile { height:2em;}
    .height-3em-mobile { height:3em;}
    .min-height-5em-mobile { min-height:5em;}
    .height-4em-mobile { height:4em;}
    .height-7em-mobile { height:7em;}
    .height-13em-mobile { height:13em;}
    .height-15em-mobile { height:15em;}
    .height-18em-mobile { height:18em;}
    .height-28em-mobile { height:28em;}
    .height-60vh-mobile { height:60vh;}
    .height-planning-line-mobile { height:2.5em;}
    .open.open-height-100-mobile { height:100%;}
    .svg-height-auto-mobile svg { height:auto;}
}

/* Marges */
.padding-0 { padding:0;}
.padding-0-25 { padding:0.25em;}
.padding-0-5 { padding:0.5em;}
.padding-0-75 { padding:0.75em;}
.padding-1,
.active-padding-1.active { padding:1em;}
.padding-1-5 { padding:1.5em;}
.padding-2 { padding:2em;}
.left-pad-0-1 { padding-left:0.1em;}
.left-pad-0-25 { padding-left:0.25em;}
.left-pad-0-5 { padding-left:0.5em;}
.left-pad-0-75 { padding-left:0.75em;}
.left-pad-1 { padding-left:1em;}
.left-pad-1-5 { padding-left:1.5em;}
.left-pad-1-75 { padding-left:1.75em;}
.left-pad-2 { padding-left:2em;}
.left-pad-2-25 { padding-left:2.25em;}
.left-pad-2-5 { padding-left:2.5em;}
.left-pad-3 { padding-left:3em;}
.left-pad-4 { padding-left:4em;}
.left-pad-5 { padding-left:5em;}
.left-pad-7 { padding-left:7em;}
.left-pad-menu { padding-left:15em;}
.right-pad-0-15 { padding-right:0.15em;}
.right-pad-0-25 { padding-right:0.25em;}
.right-pad-0-5 { padding-right:0.5em;}
.right-pad-0-75 { padding-right:0.75em;}
.right-pad-1 { padding-right:1em;}
.right-pad-1-5 { padding-right:1.5em;}
.right-pad-2 { padding-right:2em;}
.right-pad-3 { padding-right:3em;}
.right-pad-4 { padding-right:4em;}
.right-pad-5 { padding-right:5em;}
.top-pad-0-25 { padding-top:0.25em;}
.top-pad-0-5 { padding-top:0.5em;}
.top-pad-0-6 { padding-top:0.6em;}
.top-pad-0-75 { padding-top:0.75em;}
.top-pad-1 { padding-top:1em;}
.top-pad-1-5 { padding-top:1.5em;}
.top-pad-2 { padding-top:2em;}
.top-pad-3 { padding-top:3em;}
.top-pad-4 { padding-top:4em;}
.top-pad-3-5 { padding-top:3.5em;}
.top-pad-5 { padding-top:5em;}
.top-pad-7 { padding-top:7em;}
.top-pad-10 { padding-top:10em;}
.top-pad-20 { padding-top:20em;}
.bot-pad-0-25 { padding-bottom:0.25em;}
.bot-pad-0-5 { padding-bottom:0.5em;}
.bot-pad-0-75 { padding-bottom:0.75em;}
.bot-pad-1 { padding-bottom:1em;}
.bot-pad-1-5 { padding-bottom:1.5em;}
.bot-pad-2 { padding-bottom:2em;}
.bot-pad-3 { padding-bottom:3em;}
.bot-pad-3-5 { padding-bottom:3.5em;}
.bot-pad-4 { padding-bottom:4em;}
.bot-pad-5 { padding-bottom:5em;}
.bot-pad-10 { padding-bottom:10em;}
.bot-pad-20 { padding-bottom:20em;}
.padding-planning { padding-right:2em; padding-left:2em;}
.margin-0 { margin:0;}
.left-margin-auto { margin-left:auto;}
.left-margin--1px { margin-left:-1px;}
.left-margin--0-125 { margin-left:-0.125em;}
.left-margin--0-5 { margin-left:-0.5em;}
.left-margin--0-75 { margin-left:-0.75em;}
.left-margin--1 { margin-left:-1em;}
.left-margin--1-25 { margin-left:-1.25em;}
.left-margin--1-5 { margin-left:-1.5em;}
.left-margin--2 { margin-left:-2em;}
.left-margin--2-25 { margin-left:-2.25em;}
.left-margin--2-5 { margin-left:-2.5em;}
.left-margin--4 { margin-left:-4em;}
.left-margin--6-5 { margin-left:-6.5em;}
.left-margin--18 { margin-left:-18em;}
.left-margin--25 { margin-left:-25em;}
.left-margin--45 { margin-left:-45em;}
.left-margin--50 { margin-left:-50em;}
.left-margin--5p { margin-left:-5%;}
.left-margin-1px { margin-left:1px;}
.left-margin-0-125 { margin-left:0.125em;}
.left-margin-0-5 { margin-left:0.5em;}
.left-margin-0-75 { margin-left:0.75em;}
.left-margin-1 { margin-left:1em;}
.left-margin-1-5 { margin-left:1.5em;}
.left-margin-2 { margin-left:2em;}
.right-margin-auto { margin-right:auto;}
.right-margin--1px { margin-right:-1px;}
.right-margin--0-5 { margin-right:-0.5em;}
.right-margin--1 { margin-right:-1em;}
.right-margin--2 { margin-right:-2em;}
.right-margin-0-5 { margin-right:0.5em;}
.right-margin-0-75 { margin-right:0.75em;}
.right-margin-1 { margin-right:1em;}
.right-margin-1-5 { margin-right:1.5em;}
.right-margin-2 { margin-right:2em;}
.right-margin-2-5 { margin-right:2.5em;}
.right-margin-3 { margin-right:3em;}
.right-margin-4 { margin-right:4em;}
.right-margin-4-25 { margin-right:4.25em;}
.top-margin--1px { margin-top:-1px;}
.top-margin--0-05 { margin-top:-0.05em;}
.top-margin--0-1 { margin-top:-0.1em;}
.top-margin--0-175 { margin-top:-0.175em;}
.top-margin--0-2 { margin-top:-0.2em;}
.top-margin--0-25 { margin-top:-0.25em;}
.top-margin--0-375 { margin-top:-0.375em;}
.top-margin--0-5 { margin-top:-0.5em;}
.top-margin--0-625 { margin-top:-0.625em;}
.top-margin--0-75 { margin-top:-0.75em;}
.top-margin--1 { margin-top:-1em;}
.top-margin--2 { margin-top:-2em;}
.top-margin--5-5 { margin-top:-5.5em;}
.top-margin-0-15 { margin-top:0.15em;}
.top-margin-0-25 { margin-top:0.25em;}
.top-margin-0-375 { margin-top:0.375em;}
.top-margin-0-5 { margin-top:0.5em;}
.top-margin-0-75 { margin-top:0.75em;}
.top-margin-1,
.active.active-top-margin-1 { margin-top:1em;}
.top-margin-1-25 { margin-top:1.25em;}
.top-margin-1-5 { margin-top:1.5em;}
.top-margin-2 { margin-top:2em;}
.top-margin-2-5 { margin-top:2.5em;}
.top-margin-3 { margin-top:3em;}
.top-margin-3-5 { margin-top:3.5em;}
.top-margin-4 { margin-top:4em;}
.top-margin-5 { margin-top:5em;}
.top-margin-6 { margin-top:6em;}
.top-margin-7 { margin-top:7em;}
.top-margin-10 { margin-top:10em;}
.bot-margin-0-5 { margin-bottom:0.5em;}
.bot-margin-1,
.active.active-bot-margin-1 { margin-bottom:1em;}
.bot-margin-1-25 { margin-bottom:1.25em;}
.bot-margin-2,
.active.active-bot-margin-2 { margin-bottom:2em;}
.bot-margin-2-25 { margin-bottom:2.25em;}
.bot-margin-5 { margin-bottom:5em;}
.bot-margin-6-25 { margin-bottom:6.25em;}
.bot-margin-6-5 { margin-bottom:6.5em;}
.bot-margin-8 { margin-bottom:8em;}
.bot-margin--1px { margin-bottom:-1px;}
.bot-margin--0-5 { margin-bottom:-0.5em;}
.bot-margin--1 { margin-bottom:-1em;}
.bot-margin--2 { margin-bottom:-2em;}
@media only screen and (max-width: 850px) {
    .margin-auto-mobile { margin:auto;}
    .right-margin-auto-mobile { margin-right:auto;}
    .right-margin--1-mobile { margin-right:-1em;}
    .right-margin-1-5-mobile { margin-right:1.5em;}
    .right-margin--2-mobile { margin-right:-2em;}
    .left-margin-auto-mobile { margin-left:auto;}
    .left-margin-0-mobile { margin-left:0;}
    .left-margin-1-mobile { margin-left:1em;}
    .left-margin--1-mobile { margin-left:-1em;}
    .left-margin--2-mobile { margin-left:-2em;}
    .left-margin--5-mobile { margin-left:-5em;}
    .left-margin--7-mobile { margin-left:-7em;}
    .left-margin--17-5-mobile { margin-left:-17.5em;}
    .left-margin--40-mobile { margin-left:-40em;}
    .top-margin--3-5-mobile { margin-top:-3.5em;}
    .top-margin-0-mobile { margin-top:0;}
    .top-margin-1-mobile { margin-top:1em;}
    .top-margin-1-5-mobile { margin-top:1.5em;}
    .top-margin-2-mobile { margin-top:2em;}
    .bot-margin--0-5-mobile { margin-bottom:-0.5em;}
    .left-pad-0-mobile { padding-left:0;}
    .left-pad-0-75-mobile { padding-left:0.75em;}
    .left-pad-1-mobile { padding-left:1em;}
    .left-pad-2-mobile { padding-left:2em;}
    .left-pad-3-mobile { padding-left:3em;}
    .right-pad-0-mobile { padding-right:0;}
    .right-pad-0-75-mobile { padding-right:0.75em;}
    .right-pad-1-mobile { padding-right:1em;}
    .right-pad-2-mobile { padding-right:2em;}
    .padding-0-mobile { padding:0;}
    .top-pad-0-mobile { padding-top:0;}
    .top-pad-0-25-mobile { padding-top:0.25em;}
    .top-pad-0-5-mobile { padding-top:0.5em;}
    .top-pad-1-mobile { padding-top:1em;}
    .top-pad-2-mobile { padding-top:2em;}
    .top-pad-3-mobile { padding-top:3em;}
    .top-pad-5-mobile { padding-top:5em;}
    .top-pad-6-mobile { padding-top:6em;}
    .top-pad-menu-mobile { padding-top:4em;}
    .bot-pad-0-mobile { padding-bottom:0;}
    .bot-pad-0-25-mobile { padding-bottom:0.25em;}
    .bot-pad-0-5-mobile { padding-bottom:0.5em;}
    .bot-pad-1-mobile { padding-bottom:1em;}
    .bot-pad-1-5-mobile { padding-bottom:1.5em;}
    .bot-pad-2-mobile { padding-bottom:2em;}
    .bot-pad-3-mobile { padding-bottom:3em;}
    .bot-pad-6-mobile { padding-bottom:6em;}
}

/* Adaptations PDF */
.pdf { box-sizing:border-box; padding:3em;}

/* Boutons */
.btn { background-color:transparent; border:none; box-sizing:border-box; cursor:pointer; vertical-align:middle; color:white; user-select:none; -webkit-tap-highlight-color: rgba(0,0,0,0);}
.btn:not(.flexbox) { display:inline-block;}
.btn.primary { font-size:112.5%; padding:0.725em 1em; font-weight:700; border:1px solid transparent; border-radius:0.5em; transition:background-color 0.25s, color 0.25s, border-color 0.25s; appearance:none;}
.btn.small { font-size:100%; padding:0.5em 1em; font-weight:700; border-radius:0.5em; transition:background-color 0.25s, color 0.25s, border-color 0.25s; appearance:none;}
.btn.rehausse { background-color:#2B50E8; color:white;}
.btn.rehausse:hover { background-color:#1333b3;}
.btn.lightrehausse { background-color:#D9E0FB; color:#020B59;}
.btn.lightrehausse:hover { background-color:#c2cdf9;}
.btn.lightrehausse:hover { background-color:#c2cdf9;}
.btn.lightrehausse svg path { fill:#020B59 !important;}
.btn.darkblue { background-color: #020B59; color:white;}
.btn.darkblue:hover { background-color:#010527;}
.btn.bluegrey { color:#BBC3D2;}
.btn.bluegrey:hover { color:#2B50E8;}
.btn.bluegrey svg path { fill:#BBC3D2 !important;}
.btn.bluegrey:hover svg path { fill:#2B50E8 !important;}
.btn.white { background-color:white; color:#68717D; border:1px solid #E5E5E5;}
.btn.white:hover { background-color:#e5e5e5;}
.btn.yellow { background-color:#FBCE2F; color:black;}
.btn.yellow:hover { background-color: black; color:#FBCE2F;}
.btn.activable { background-color:#BBC3D2; color:white; cursor:initial;}
.btn.activable.active { background-color:#2B50E8; cursor:pointer;}
.btn.activable.active:hover { background-color:#1333b3;}
.btn.toggleable { background-color:white; color:#2B50E8; border-color:#2B50E8; cursor:pointer;}
.btn.toggleable svg path { fill:#2B50E8 !important;}
.btn.toggleable.active { background-color:#2B50E8; color:white; cursor:pointer;}
.btn.toggleable.active svg path { fill:white !important;}
.btn.pending { background-color:grey !important; pointer-events:none; padding-right:2.75em;}
.btn.pending:after { content:''; position:absolute; top:50%; margin-top:-0.5em; right:1em; display:block; width:1em; height:1em; border:0.2em solid #FFF; border-bottom-color: transparent; border-radius:50%; box-sizing: border-box; animation: rotate 1s linear infinite;}
.btn.icon { padding-right:2.15em;}
.btn.icon .icon-holder { position:absolute; display:flex; top:50%; margin:-0.65em 0.6em 0 0; right:0; width:1em; height:1.25em;}
.btn.icon .icon-holder svg path { transition:fill 0.25s; -webkit-transition:fill 0.25s;}
.btn.icon.white svg path { fill:#68717D;}
.btn.burger { padding:0;}
.btn.burger .content { display:none;}
.btn.burger .icon-holder { position:relative; display:flex; top:auto; margin:0; right:auto; width:2.25em; height:2.25em;}
.btn.burger .icon-holder svg .top,
.btn.burger .icon-holder svg .mid,
.btn.burger .icon-holder svg .bot { transform-box: fill-box; transform-origin: center;}
.btn.burger.active .icon-holder svg .top { animation: down-rotate 0.25s ease-out both;}
.btn.burger.active .icon-holder svg .bot { animation: up-rotate 0.25s ease-out both;}
.btn.burger.active .icon-holder svg .mid { animation: hide 0.25s ease-out forwards;}
@media only screen and (max-width: 850px) {
    .btn.icon:not(.burger) { padding:0.5em;}
    .btn.icon:not(.burger) .content { display:none;}
    .btn.icon:not(.burger) .icon-holder { position:relative; display:flex; top:auto; margin:0; right:auto; width:1em; height:1.25em; padding:0 0.125em;}
}


/* Formulaires */
input,
textarea,
select { font-size:inherit; font-style:inherit; font-family:inherit; font-weight:inherit; resize:none;}
.fake-input { appearance:none; width:1px; height:1px; border:none; outline:none; padding:0; margin:0; background-color:transparent; opacity:0;}
.form .field { display:inline-block;}
.form .field .item + .item { margin-top:0.75em;}
.form .field.combo > label,
.form .field.select > label,
.form .field.textarea > label,
.form .field.rich > label,
.form .field.color > label,
.form .field.file > label,
.form .field.text > label { display:block; text-transform:uppercase; line-height:1.1718em; letter-spacing:0.08em; font-weight:500;}
.form .field.text > label + .items-container,
.form .field.text > label + .items-container,
.form .field.color > label + .items-container,
.form .field.combo > label + .items-container,
.form .field.textarea > label + .items-container,
.form .field.file > label + .items-container,
.form .field.select > label + .items-container { margin-top:0.5em;}
.form .field.select select {cursor:pointer;}
.form .field.text textarea,
.form .field.select select,
.form .field.textarea textarea,
.form .field.text input { width:100%; height:2.88em; line-height:2.88em; padding:0 0.75em; background-color:#FFF; border:1px solid #BBC3D2; outline:none; border-radius:0.5em; box-sizing:border-box; color:inherit; transition:border-color 0.25s; -webkit-transition:border-color 0.25s;}
.form .field.textarea textarea { height:auto; line-height:1.17em; padding:0.75em;}
.form .field.text.editable input { height:auto; line-height:inherit; padding:0; background-color:transparent; border:none; border-radius:initial;}
.form .field.text.editable.active input { background-color:rgba(255,255,255,0.3); border-radius:0.25em; padding:0 0.25em;}
.form .field.text input::placeholder { font-style:italic; color:#032663; opacity:0.3;}
.form .field.text input::-ms-input-placeholder { font-style:italic; color:#032663; opacity:0.3;}
.form .field.text input::-webkit-input-placeholder { font-style:italic; color:#032663; opacity:0.3;}
.form .field.text textarea::placeholder { font-style:italic; color:#032663; opacity:0.3;}
.form .field.text textarea::-ms-input-placeholder { font-style:italic; color:#032663; opacity:0.3;}
.form .field.text textarea::-webkit-input-placeholder { font-style:italic; color:#032663; opacity:0.3;}
.form .field.radio + .field.radio { margin-top:1.5em;}
.form .field.radio label { font-size:125%;}
.form .field.radio .options { margin-top:0.75em;}
.form .field.radio .options .option { display:inline-block; cursor:pointer; user-select:none;}
.form .field.radio .options .option + .option { margin-left:1em;}
.form .field.radio .options .option label { vertical-align:middle; font-size:112.5%; cursor:inherit; color:#495057;}
.form .field.radio .options .option input { vertical-align:middle; appearance:none; -webkit-appearance:none; margin:0 0.25em 0 0; padding:0; height:1.125em; width:1.125em; border-radius:50%; border:1px solid #DDE2E5; cursor:inherit;}
.form .field.radio .options .option input:checked { border:4px solid #4263EB;}
.form .field.radio .options .option input:checked + label { font-weight:bold;}
.form .field.check .item > label { display:inline-block; font-weight:700; font-size:112.5%; color:#495057; padding-left:1.5em; cursor:pointer;}
.form .field.check input { position:absolute; padding:0; margin:-0.5em 0 0 0; left:0; top:50%; height:1em; width:1em; cursor:pointer;}
.form .field { display:inline-block;}
.form .field input[type="date"]::-webkit-calendar-picker-indicator { background-image: none;}
.form .field input[type="datetime-local"]::-webkit-calendar-picker-indicator { background-image: none;}
/* File */
.form .field.file input[type="file"] { width:1px; height:1px; opacity:0; overflow:hidden; appearance:none; position:absolute; pointer-events:none;}
.form .field.file > label + .input-container { margin-top:0.5em;}
.form .field.file .input-container { z-index:4; display:flex; align-items:center; justify-content:center; gap:0.5em; margin-top:0.5em; border:1px solid rgba(0,0,0,0); outline:none; border-radius:0.5em; transition:border-radius 0.15s; -webkit-transition:border-radius 0.15s;}
.form .field.file .input-container .left-part { height:100%; line-height:inherit; padding:0 0.75em 0 0; box-sizing:border-box; transition:padding 0.25s; -webkit-transition:padding 0.25s;}
.form .field.file .input-container .left-part label { display: inline-block; font-size: 100%; padding: 0.5em 0.75em; line-height: 1em; cursor: pointer; border: 1px solid rgba(0,0,0,0); user-select: none;}
.form .field.file .input-container .image-container { cursor:pointer; z-index:2; padding:0; margin:0; width:2.88em; height:2.88em; border:1px solid #BBC3D2; border-radius:50%; overflow:hidden; box-sizing:border-box; background-repeat:no-repeat; background-size:cover; background-position:center center;}
.form .field.file .input-container .image-container .content { width:100%; height:100%;}
.form .field.file .input-container .image-container .content img { width:100%; height:100%; vertical-align:middle; object-fit:cover; object-position:center; border:none; outline:none; box-sizing:border-box;}
.form .field.file .input-container .text-container { flex:1;}
.form .field.file.gallery .items-container { display:flex; gap:0 2em;}
.form .field.file.gallery .item { margin-top:0;}
.form .field.file.gallery .input-container { margin-top:0; flex-direction:column;}
.form .field.file.gallery .input-container .left-part { padding:0;}
.form .field.file.gallery .input-container .image-container { width:auto; height:10em; border-radius:0.5em;}
.form .field.file.gallery .input-container .image-container .content { width:auto; height:100%;}
.form .field.file.gallery .input-container .image-container .content img { width:auto; height:100%;}
.form .field.file .item.empty .input-container .image-container img { opacity:0;}

/* Color */
.form .field.color > label + .input-container { margin-top:0.5em;}
.form .field.color .input-container { z-index:4; display:flex; margin-top:0.5em; height:2.88em; line-height:2.88em; background-color:#FFF; border:1px solid #BBC3D2; outline:none; border-radius:0.5em; transition:border-radius 0.15s; -webkit-transition:border-radius 0.15s;}
.form .field.color .input-container .color-container { z-index:2; position:absolute; padding:0; margin:0; top:50%; margin-top:-0.75em; margin-left:0.75em; width:1.5em; height:1.5em; border:1px solid #BBC3D2; border-radius:1em; overflow:hidden; box-sizing:border-box;}
.form .field.color .input-container .color-container input[type="color"] { position:absolute; padding:0; margin:0; top:-100%; left:-100%; width:300%; height:300%; border:none; cursor:pointer;}
.form .field.color input.hexa-value { z-index:1; width:100%; height:100%; line-height:2.88em; padding:0 0.75em; padding-left:3em; background-color:transparent; border:none; border-radius:0.5em; box-sizing:border-box; color:inherit;}
/* Combo */
.form .field.combo .input-container { z-index:4; cursor:pointer; min-width:10em; display:flex; height:2.88em; line-height:2.88em; background-color:#FFF; border:1px solid #BBC3D2; box-sizing:border-box; outline:none; border-radius:0.5em; transition:border-radius 0.15s, border-color 0.25s; -webkit-transition:border-radius 0.15s, border-color 0.25s;}
.form .field.combo label + .input-container { margin-top:0.5em;}
.form .field.combo .selection { display:flex; align-items:center; justify-content:center; height:100%; line-height:inherit; padding:0 0.3em; box-sizing:border-box; transition:padding 0.25s; -webkit-transition:padding 0.25s;}
.form .field.combo .selection:empty { padding:0;}
.form .field.combo .datalist { max-height:0; pointer-events:none; opacity:0; overflow:hidden; z-index:10; position:absolute; top:100%; width:100%; box-sizing:border-box; padding:0; background-color:white; border:1px solid #BBC3D2; border-top:none; border-bottom-left-radius:0.5em; border-bottom-right-radius:0.5em; transition:max-height 0.25s, padding 0.25s, opacity 0.25s; -webkit-transition:max-height 0.25s, padding 0.25s, opacity 0.25s;}
.form .field.combo .datalist .content { margin-top:0.25em; max-height:10em; overflow-y:auto; overflow-x:clip;}
.form .field.combo .datalist .search-zone input { width:100%; opacity:0.6; height:2.25em; line-height:2.25em; border-radius:0.25em; padding:0 0.75em 0 2.25em; background-color:#F0F7FC; border:none; outline:none; box-sizing:border-box; color:inherit; transition:height 0.25s; -webkit-transition:height 0.25s;}
.form .field.combo-select.nosearch .datalist .search-zone { position:absolute; left:50%; top:0; width:1px; opacity:0;}
.form .field.combo-select.nosearch .datalist .search-zone input { height:1px; padding:0;}
.form .field.combo.open .datalist { max-height:100vh; opacity:1; padding:0.25em; pointer-events:auto;}
.form .field.combo:not(.open) .datalist .search-zone input { height:1px;}
.form .field.combo.open .input-container { border-bottom-left-radius:0; border-bottom-right-radius:0; /*border-color:#4263EB;*/}
.form .field.combo .selection .option,
.form .field.combo .datalist .option { display:inline-block; font-size:100%; padding:0.5em; line-height:1em; cursor:pointer; border:1px solid rgba(0,0,0,0); user-select:none;}
.form .field.combo .datalist .option + .option { margin-top:0.5em;}
.form .field.combo-std .selection .option,
.form .field.combo-select .selection .option,
.form .field.combo-std .datalist .option:hover,
.form .field.combo-select .datalist .option:hover { border-color:#BBC3D2; border-radius:0.5em;}
.form .field.combo-select.simple .selection .option { border-color:rgba(0,0,0,0); border-radius:0;}
.form .field.combo-std .selection .option + .option,
.form .field.combo-select .selection .option + .option { margin-left:0.25em;}
.form .field.combo-std .selection .option .remove,
.form .field.combo-select .selection .option .remove { padding-left:0.5em; transition:color 0.25s; -webkit-transition:color 0.25s;}
.form .field.combo-std .selection .option .remove:hover,
.form .field.combo-select .selection .option .remove:hover { color:#FF656C;}
.form .field.combo-check .input-container,
.form .field.combo-select .input-container { padding-right:3em;}
.form .field.combo-check .zone,
.form .field.combo-select .zone { cursor:pointer;}
.form .field.combo-check .selection { display:none;}
.form .field.combo-check .selection-count { padding-left:0.75em; user-select:none; min-width:8em;}
.form .field.combo-check .datalist .option label { cursor:pointer;}
.form .field.lined { display:flex;}
.form .field.lined > label { text-transform:none; font-weight:initial; padding-right:0.75em; letter-spacing:initial;}
.form .field.lined > label,
.form .field.lined > label + .items-container,
.form .field.lined .item .input-container { margin-top:0;}
.form .field.rich .input-container { z-index:4; margin-top:0.5em; padding:0.75em; background-color:#FFF; border:1px solid #BBC3D2; outline:none; border-radius:0.5em; transition:border-radius 0.15s; -webkit-transition:border-radius 0.15s;}
.form .field.rich .input-container > div { line-height:1.17em;}
.ck.ck-balloon-panel { box-shadow:none !important; border-radius:0.5em 0.5em 0 0 !important;}
.ck.ck-balloon-panel .ck-toolbar { border-radius:0.5em 0.5em 0 0 !important;}
.ck.ck-balloon-panel.ck-powered-by-balloon { display:none !important;}
/* Ombrage */
.form .field.text textarea,
.form .field.select select,
.form .field.textarea textarea,
.form .field.text input,
.form .field.rich .input-container,
.form .field.color .input-container,
.form .field.combo .input-container { box-shadow: 0 0.3125em 0.625em 0 rgba(3, 38, 99, 0.025);}

/* Readonly */
.form .field.readonly select,
.form .field.readonly textarea,
.form .field.readonly input { background-color:#F8FCFF; cursor:default; color:#9fa6b3;}
.form .field.color.readonly .input-container,
.form .field.combo.readonly .input-container,
.form .field.rich.readonly .input-container { background-color:#F8FCFF; cursor:default; color:#9fa6b3;}
.form .field.file.readonly .input-container label { display:none;}
.form .field.combo.readonly .selection .option { cursor:default;}
.form .field.combo-check.readonly .zone:after,
.form .field.combo-select.readonly .zone:after { display:none;}
.form .field.combo-check.readonly .input-container,
.form .field.combo-select.readonly .input-container { padding-right:0;}
.form .field.radio .options .option input:read-only:checked { border-color:rgb(160, 160, 160); opacity:0.4;}
.form .field.radio.readonly .options .option { pointer-events:none; cursor:default;}
/* Validation */
input.validator { position:absolute; opacity:0; pointer-events:none; top:0; left:0; width:100%; height:100%; border:none; padding:0;}
.validation-group { transition:border-color 0.25s;}
.validation-group.invalid,
.form .field.text select:invalid,
.form .field.text textarea:invalid,
.form .field.textarea textarea:invalid,
.form .field.text input:invalid,
.form .field.combo.invalid .input-container { border-color:#FF656C !important;}
/* Themes de champs */
.form .field.grey > label { color:#BBC3D2;}

/* Foldable */
.folder { max-height:0; pointer-events:none; overflow:hidden; transition:max-height 0.25s, opacity 0.25s; -webkit-transition:max-height 0.25s, opacity 0.25s;}
.active > .folder.active-direct-parent-unfold,
.folder.active-unfold.active { max-height:100vh; pointer-events:auto;}
.open .folder.open-parent-unfold,
.spe-parent.open .folder.open-spe-parent-unfold { max-height:50vh; pointer-events:auto;}
@media only screen and (max-width: 850px) {
    .folder-mobile { max-height:0; pointer-events:none; overflow:hidden; transition:max-height 0.25s, opacity 0.25s; -webkit-transition:max-height 0.25s, opacity 0.25s;}
    .folder-mobile.active-unfold-mobile.active { max-height:100vh; pointer-events:auto;}
}

/* Rotatable */
.open .open-parent-rotated-180,
.spe-parent.open .open-spe-parent-rotated-180,
.active .active-parent-rotated-180,
.spe-parent.active .active-spe-parent-rotated-180 { transform:rotate(180deg); -webkit-transform:rotate(180deg);}

/* Scrollable */
.scrollable { overflow:hidden; overflow-y:auto;}
.scrollable-x { overflow:hidden; overflow-x:auto;}
.hide-scrollbar { -ms-overflow-style:none; scrollbar-width:none;}
.hide-scrollbar::-webkit-scrollbar { display:none;}
@media only screen and (max-width: 850px) {
    .scrollable-x-mobile { overflow:hidden; overflow-x:auto;}
}

/* Texte */
.emoji { font-family:'EmojiSymbols';}
.t-huge { font-size:400%; line-height:1.172em;}
.t-bigger { font-size:200%; line-height:1.172em;}
.t-big { font-size:175%; line-height:1.1428em;}
.t-mider { font-size:150%; line-height:1.172em;}
.t-mid { font-size:125%; line-height:1.172em;}
.t-std { font-size:112.5%; line-height:1.172em;}
.t-small { font-size:100%; line-height:1.172em;}
.t-smaller { font-size:87.5%; line-height:1.172em;}
.t-smallest { font-size:75%; line-height:1.172em;}
.underline { text-decoration:underline;}
.uppercase { text-transform:uppercase;}
.lowercase { text-transform:lowercase;}
.italic { font-style:italic;}
.light { font-weight:100;}
.medium { font-weight:500;}
.bold,
.active.active-bold { font-weight:bold;}
.bolder { font-weight:750;}
.boldest { font-weight:900;}
.line-height-std { line-height:1em;}
.line-height-classic { line-height:1.1718em;}
.letter-spacing-label { letter-spacing:0.08em;}

/* Dégradés */
.gradient-white-transparent { background-color:transparent; background-image: linear-gradient(90deg, rgba(255,255,255,0.4), rgba(0,0,0,0));}
.gradient-rehausse-transparent { background-image: linear-gradient(90deg, rgb(66, 100, 235), rgba(0,0,0,0));}
.gradient-purple { background-color:#DCAFF9; background-image: linear-gradient(137deg, #DCAFF9, #AF6AEB);}
.gradient-red { background-color:#FF959A; background-image: linear-gradient(137deg, #FF959A, #FD5B62);}
.gradient-blue { background-color:#A7ACFD; background-image: linear-gradient(137deg, #A7ACFD, #7879FF);}
.gradient-green { background-color:#62F4D1; background-image: linear-gradient(137deg, #62F4D1, #3ACFAF);}
.gradient-yellow { background-color:#FFE679; background-image: linear-gradient(137deg, #FFE679, #FDC844);}
.gradient-orange { background-color:#FFBFA7; background-image: linear-gradient(137deg, #FFBFA7, #FF8E6E);}
.gradient-white-alpha-h { background-image: linear-gradient(90deg, #FFFFFF, rgba(0,0,0,0));}
.gradient-white-alpha-h-inv { background-image: linear-gradient(270deg, #FFFFFF, rgba(0,0,0,0));}

/* Couleurs */
.color-inherit { color:inherit;}
.color-red,
.hover-color-red:hover { color:#FF656C;}
.active-color-white.active,
.active .active-parent-color-white,
.hover-color-white:hover,
.selected-color-white.selected,
:hover > .hover-direct-parent-color-white,
.active > .active-direct-parent-color-white,
.color-white { color:white;}
.active-color-white-important.active { color:white !important;}
.color-black { color:black;}
.color-bluegrey { color:#BBC3D2;}
.color-mediumgrey { color:#68717D;}
.hover-color-darkblue:hover,
.active-color-darkblue.active,
.active .active-parent-color-darkblue,
.active > .active-direct-parent-color-darkblue,
.spe-parent.active .active-spe-parent-color-darkblue,
.color-darkblue { color:#020B59;}
.hover-color-rehausse:hover,
.active-color-rehausse.active,
.selected-color-rehausse.selected,
.active .active-parent-color-rehausse,
.active > .active-direct-parent-color-rehausse,
:hover > .hover-direct-parent-color-rehausse,
.color-rehausse { color:#2B50E8;}
.color-valid { color:white; background-color:#74D8BC !important;}
.color-wrong { color:white; background-color:#FF656C !important;}
.color-progress-red { color:#FB6962;}
.color-progress-grey { color:#D9D9D9;}
.color-progress-yellow { color:#FCD92F;}
.color-progress-green { color:#4FCB83;}
.color-namegrey { color:#43484F;}
.bg-color-backgrey { background-image:none; background-color:#F5F5F5;}
.selected-bg-color-rehausse.selected,
.active-bg-color-rehausse.active,
.active .active-parent-bg-color-rehausse,
.active > .active-direct-parent-bg-color-rehausse,
.spe-parent.active .active-spe-parent-bg-color-rehausse,
.bg-color-rehausse { background-image:none; background-color:#2B50E8;}
.active-bg-color-rehausse-important.active { background-image:none !important; background-color:#2B50E8 !important;}
.active-bg-color-alpharehausse.active,
.bg-color-alpharehausse { background-image:none; background-color:rgba(43, 81, 232, 0.15);}
.active .active-parent-bg-color-lightrehausse,
.active > .active-direct-parent-bg-color-lightrehausse,
.active-bg-color-lightrehausse.active,
.bg-color-lightrehausse { background-image:none; background-color:#D9E0FB;}
.bg-color-lighterrehausse { background-image:none; background-color:#E5EEFF;}
.bg-color-lightestrehausse { background-image:none; background-color:#F9FAFF;}
.bg-color-darkgrey { background-image:none; background-color:#D7DBE4;}
.active .active-parent-bg-color-darkblue,
.active > .active-direct-parent-bg-color-darkblue,
.bg-color-darkblue { background-image:none; background-color:#020B59;}
.bg-color-alpha-green { background-color:rgba(1,179,198,0.6);}
.active .active-parent-bg-color-white,
.active-bg-color-white.active,
.selected-bg-color-white.selected,
.hover-bg-color-white:hover,
.bg-color-white { background-image:none; background-color:white;}
.bg-color-highlight { background-image:none; background-color:rgb(255, 250, 228);}
.hover-bg-color-red:hover,
.bg-color-red { background-image:none; background-color:#FFAFB3;}
.hover-bg-color-green:hover,
.bg-color-green { background-image:none; background-color:#01B3C6;}
.hover-bg-color-darkgreen:hover,
.bg-color-darkgreen { background-image:none; background-color:#0A424A;}
.hover-bg-color-lightgreen:hover,
.bg-color-lightgreen { background-image:none; background-color:#D9F4F7;}
.hover-bg-color-lightergreen:hover,
.bg-color-lightergreen { background-image:none; background-color:#EBF9FB;}
.hover-bg-color-blue:hover,
.bg-color-blue { background-image:none; background-color:#7879FF;}
.hover-bg-color-purple:hover,
.bg-color-purple { background-image:none; background-color:#AF6AEB;}
.hover-bg-color-yellow:hover,
.bg-color-yellow { background-image:none; background-color:#FCD92F;}
.hover-bg-color-orange:hover,
.bg-color-orange { background-image:none; background-color:#FF8E6E;}
:hover > .hover-direct-parent-bg-color-lightestgrey,
.bg-color-lightestgrey { background-image:none; background-color:#f7f7f7;}
.selected .selected-parent-bg-color-lightergrey,
.selected-bg-color-lightergrey.selected,
.active-bg-color-lightergrey.active,
.hover-bg-color-lightergrey:hover,
.active .active-parent-bg-color-lightergrey,
.bg-color-lightergrey { background-image:none; background-color:#F0F3FD;}
.hover-bg-color-lightgrey:hover,
.bg-color-lightgrey { background-image:none; background-color:#ECEEF5;}
.hover-bg-color-bluegrey:hover,
.bg-color-bluegrey { background-image:none; background-color:#BBC3D2;}
.hover-bg-color-mediumgrey:hover,
.bg-color-mediumgrey { background-image:none; background-color:#68717D;}
.bg-color-valid { background-image:none; background-color:#56d47c;}
.bg-color-progress-green { background-image:none; background-color:#4FCB83;}
.bg-color-progress-yellow { background-image:none; background-color:#FCD92F;}
.bg-color-progress-red { background-image:none; background-color:#FB6962;}
.bg-color-progress-grey { background-image:none; background-color:#D9D9D9;}
.border-color-green { border-color:#01B3C6 !important;}
.border-color-darkblue { border-color:#020B59 !important;}
.border-color-bluegrey { border-color:#BBC3D2 !important;}
.border-color-lightgrey { border-color:#E5E5E5 !important;}
.border-color-grey { border-color:#DDE2E5 !important;}
.border-color-red { border-color:#FF656C !important;}
.border-color-white { border-color:white !important;}
.selected-border-color-rehausse.selected,
.hover-border-color-rehausse:hover,
:hover > .hover-direct-parent-border-color-rehausse,
.border-color-rehausse { border-color:#2B50E8 !important;}
.fill-none svg path { fill:none !important;}
.stroke-color-white svg path { stroke:white !important;}
.fill-color-menugrey svg path { fill:#bbc3d2 !important;}
.fill-color-bluegrey svg path { fill:#BBC3D2 !important;}
.fill-color-darkblue svg path { fill:#020B59 !important;}
.active .active-parent-fill-color-rehausse svg path,
.fill-color-rehausse svg path,
:hover > .hover-direct-parent-fill-color-rehausse svg path { fill:#2B50E8 !important;}
.fill-color-black svg path { fill:black !important;}
.fill-color-mediumgrey svg path { fill:#68717D !important;}
.active .active-parent-fill-color-white svg path,
.fill-color-white svg path { fill:white !important;}
.hover-fill-color-white:hover svg path { fill:white !important;}
.fill-color-rehausse svg path,
:hover > .hover-direct-parent-fill-color-rehausse svg path,
.hover-fill-color-rehausse:hover svg path { fill:#2B50E8 !important;}
.fill-color-valid svg path { fill:#4FCB83 !important;}
.fill-color-invalid svg path { fill:#FB6962 !important;}
.hover-stroke-color-darkgreen:hover svg path { stroke:#0A424A;}
@media only screen and (max-width: 850px) {
    .color-darkblue-mobile { color:#020B59;}
    .spe-parent.active .active-spe-parent-color-rehausse-mobile { color:#2B50E8;}
    .bg-color-lightestrehausse-mobile { background-image:none; background-color:#F9FAFF;}
    .spe-parent.active .active-spe-parent-bg-color-lightrehausse-mobile { background-image:none; background-color:#D9E0FB;}
    .selected .selected-parent-bg-color-alpha-mobile { background-image:none; background-color:rgba(0,0,0,0);}
    :hover > .hover-direct-parent-bg-color-alpha-mobile { background-image:none; background-color:rgba(0,0,0,0);}
    .selected-bg-color-lightergrey-mobile.selected { background-image:none; background-color:#F0F3FD;}
}


.blur { filter:blur(50px);}
.transition-full-color { -webkit-transition:color 0.25s, background-color 0.25s, border-color 0.25s; transition:color 0.25s, background-color 0.25s, border-color 0.25s;}
.transition-full-color svg path { -webkit-transition:fill 0.25s; transition:fill 0.25s;}
.transition-t-color { -webkit-transition:color 0.25s; transition:color 0.25s;}
.transition-bg-color { -webkit-transition:background-color 0.25s; transition:background-color 0.25s;}
.transition-border-color { -webkit-transition:border-color 0.25s; transition:border-color 0.25s;}
.transition-fill-color svg path { -webkit-transition:fill 0.25s; transition:fill 0.25s;}

/* Ombres */
.shadow-modal { box-shadow: 0 0 5em rgba(0,0,0,0.1);}

/* Arrondis */
.rounded { border-radius:50%;}
.rounded-0-25em { border-radius:0.25em;}
.rounded-0-5em { border-radius:0.5em;}
.rounded-left-0-5em { border-top-left-radius:0.5em; border-bottom-left-radius:0.5em;}
.rounded-top-left-0-5em { border-top-left-radius:0.5em;}
.rounded-right-0-5em { border-top-right-radius:0.5em; border-bottom-right-radius:0.5em;}
.rounded-top-right-0-5em { border-top-right-radius:0.5em;}
.rounded-1em { border-radius:1em;}
.border-separate { border-collapse:separate;}
@media only screen and (max-width: 850px) {
    .rounded-0-5em-mobile { border-radius:0.5em;}
}

/* Bordure */
.border-1 { border:1px solid rgba(0,0,0,0);}
.border-2 { border:2px solid rgba(0,0,0,0);}
.active > .active-direct-parent-border-right-2,
.border-right-2 { border-right:2px solid rgba(0,0,0,0);}
.border-right-1 { border-right:1px solid rgba(0,0,0,0);}
.active > .active-direct-parent-border-top-1,
.border-top-1 { border-top:1px solid rgba(0,0,0,0);}
.active > .active-direct-parent-border-top-2,
.border-top-2 { border-top:2px solid rgba(0,0,0,0);}
.active > .active-direct-parent-border-left-2,
.border-left-2 { border-left:2px solid rgba(0,0,0,0);}
.border-left-1 { border-left:1px solid rgba(0,0,0,0);}
.active > .active-direct-parent-border-bot-2,
.border-bot-2 { border-bottom:2px solid rgba(0,0,0,0);}
.border-bot-1 { border-bottom:1px solid rgba(0,0,0,0);}
.border-dashed { border-style:dashed;}
@media only screen and (max-width: 850px) {
    .border-none-mobile { border:none !important;}
    .active-border-top-2-mobile.active { border-top:2px solid rgba(0,0,0,0);}
    .active-border-bot-2-mobile.active { border-bottom:2px solid rgba(0,0,0,0);}
    .active-border-right-2-mobile.active { border-right:2px solid rgba(0,0,0,0);}
}


/* Visibilité */
.transition-opacity { -webkit-transition:opacity 0.25s; transition:opacity 0.25s;}
.active > .active-direct-parent-opacity-0,
.opacity-0 { opacity:0;}
.opacity-20 { opacity:0.2;}
.opacity-40 { opacity:0.4;}
.opacity-60 { opacity:0.6;}
.opacity-80 { opacity:0.8;}
.opacity-100,
.active > .active-direct-parent-opacity-100,
.active .active-parent-opacity-100,
.active-opacity-100.active { opacity:1;}
.active .active-parent-hidden,
.hidden { display:block; position:absolute; opacity:0; width:0; min-width:0; height:0; min-height:0; padding:0; margin:0; overflow:hidden; pointer-events:none;}
.active .active-parent-visible { width:auto; min-width:auto; height:auto; pointer-events:all;}
.empty-hidden:empty { display:none;}
.overflow-hidden { overflow:hidden;}
.overflow-y { overflow-y:auto;}
@media only screen and (max-width: 850px) {
    .opacity-0-mobile { opacity:0;}
    .open .open-parent-opacity-100-mobile { opacity:1.0;}
    .overflow-hidden-mobile { overflow:hidden;}
    .hidden-mobile { display:block; position:absolute; width:0; min-width:0; height:0; min-height:0; padding:0; margin:0; overflow:hidden; pointer-events:none;}
    .active-visible-mobile.active { position:relative; overflow:initial; width:auto; min-width:auto; height:auto; pointer-events:all;}
}

/* Transformations */
.transition-transform { transition:transform 0.25s, -webkit-transform 0.25s, opacity 0.25s; -webkit-transition:transform 0.25s, opacity 0.25s, -webkit-transform 0.25s, opacity 0.25s;}
.translate-x-100 { transform:translateX(100%); -webkit-transform:translateX(100%);}
.active > .active-direct-parent-translate-x-0 { transform:translateX(0); -webkit-transform:translateX(0);}
@media only screen and (max-width: 850px) {
    .translate-x-100-mobile { transform:translateX(100%); -webkit-transform:translateX(100%);}
    .translate-x--100-mobile { transform:translateX(-100%); -webkit-transform:translateX(-100%);}
    .open .open-parent-translate-x-0-mobile { transform:translateX(0); -webkit-transform:translateX(0);}
    .translate-y--100-mobile { transform:translateY(-100%); -webkit-transform:translateY(-100%);}
    .open .open-parent-translate-y-0-mobile { transform:translateY(0); -webkit-transform:translateY(0);}
}

/* Durée transition */
.transition-duration-0-5 { transition-duration:0.5s !important; -webkit-transition-duration:0.5s !important;}
.transition-duration-1 { transition-duration:1s !important; -webkit-transition-duration:1s !important;}

/* Animations */
@keyframes rotate {
    0% { -webkit-transform:rotate(0deg); -moz-transform:rotate(0deg); -ms-transform:rotate(0deg); transform:rotate(0deg);}
    100% { -webkit-transform:rotate(360deg); -moz-transform:rotate(360deg); -ms-transform:rotate(360deg); transform:rotate(360deg);}
}
@-webkit-keyframes rotate {
    0% { -webkit-transform:rotate(0deg); transform:rotate(0deg);}
    100% { -webkit-transform:rotate(360deg); transform:rotate(360deg);}
}
@keyframes fade-in {
    0% { opacity:0;}
    100% { opacity:1;}
}
@-webkit-keyframes fade-in {
    0% { opacity:0;}
    100% { opacity:1;}
}
@keyframes pop-in {
    0% { opacity:0; -webkit-transform:scale(0.8); -moz-transform:scale(0.8); -ms-transform:scale(0.8); transform:scale(0.8);}
    100% { opacity:1; -webkit-transform:scale(1); -moz-transform:scale(1); -ms-transform:scale(1); transform:scale(1);}
}
@-webkit-keyframes pop-in {
    0% { opacity:0; -webkit-transform:scale(0.8); transform:scale(0.8);}
    100% { opacity:1; -webkit-transform:scale(1); transform:scale(1);}
}

/* Bouton menu */
@keyframes up-rotate {
	0% { animation-timing-function: cubic-bezier(0.16, -0.88, 0.97, 0.53); transform: translateY(0px);}
	30% { transform-origin: center; animation-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1); transform: translateY(-10px);}
	100% { transform-origin: center; transform: translateY(-10px) rotate(45deg) scale(0.9);}
}
@-webkit-keyframes up-rotate {
	0% { animation-timing-function: cubic-bezier(0.16, -0.88, 0.97, 0.53); transform: translateY(0px);}
	30% { transform-origin: center; animation-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1); transform: translateY(-10px);}
	100% { transform-origin: center; transform: translateY(-10px) rotate(45deg) scale(0.9);}
}
@keyframes down-rotate {
	0% { animation-timing-function: cubic-bezier(0.16, -0.88, 0.97, 0.53); transform: translateY(0px);}
	30% { transform-origin: center; animation-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1); transform: translateY(10px);}
	100% { transform-origin: center; transform: translateY(10px) rotate(-45deg) scale(0.9);}
}
@-webkit-keyframes down-rotate {
	0% { animation-timing-function: cubic-bezier(0.16, -0.88, 0.97, 0.53); transform: translateY(0px);}
	30% { transform-origin: center; animation-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1); transform: translateY(10px);}
	100% { transform-origin: center; transform: translateY(10px) rotate(-45deg) scale(0.9);}
}
@keyframes hide {
	29% { opacity: 1;}
	30% { opacity: 0;}
	100% { opacity: 0;}
}
@-webkit-keyframes hide {
	29% { opacity: 1;}
	30% { opacity: 0;}
	100% { opacity: 0;}
}

/* Grilles */
.grid-container { display:grid;}
.grid { width:100%; display:-ms-grid; display:grid; -ms-grid-gap:1em 1em; grid-gap:1em 1em; height:100%; grid-auto-columns:minmax(0, 1fr); grid-auto-rows:minmax(0, 1fr); -ms-grid-columns:1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; -ms-grid-rows:1fr 1fr 1fr 1fr 1fr;}

/* Contenus standards */
.classic-content { line-height:1.25em;}
.classic-content a { color:inherit;}
.classic-content a:hover { opacity:0.6;}
.classic-content p + p { margin-top:1.5em;}
.classic-content * + ul { margin-top:0.5em;}
.classic-content ul,
.classic-content ol { margin:0.5em 0; list-style:none; }
.classic-content ol { counter-reset:item;}
.classic-content ul li,
.classic-content ol li { padding-left:0.25em; counter-increment:item;}
.classic-content ul li:before { display:inline-block; content:"•"; padding-right:0.5em; font-weight:900; font-size:110%;}
.classic-content ol li:before { display:inline-block; content:counter(item) "."; padding-right:0.5em; font-weight:900; font-size:110%;}
.classic-content ul li + li,
.classic-content ol li + li { margin-top:0.15em;}
.classic-content ul ul { padding-left:0.5em;}
.classic-content ul ul li:before { content:"◦";}
.classic-content ul ul ul li:before { content:"•";}
.classic-content ul ul ul ul li:before { content:"◦";}
.classic-content img { max-width:100%; margin:1em auto 1em auto;}
.classic-content .image-style-side { float:left; width:50%; margin:1em; margin-left:0;}
.classic-content.column-count-2 { column-count:2; column-gap:2em;}
@media only screen and (max-width: 850px) {
    .classic-content.column-count-2 { column-count:1;}
}