html, body {
  background-color: #fff;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 16px;
}

input, select, button {
  color: #032663;
  font-size: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  font-family: inherit;
}

@media only screen and (max-width: 1700px) {
  html, body {
    font-size: 15px;
  }

  @media only screen and (max-width: 1400px) {
    html, body {
      font-size: 14px;
    }

    @media only screen and (max-width: 1300px) {
      html, body {
        font-size: 13px;
      }

      @media only screen and (max-width: 1200px) {
        html, body {
          font-size: 12px;
        }

        @media only screen and (max-width: 850px) {
          html, body {
            font-size: 13px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 2000px) {
  html, body {
    font-size: 17px;
  }

  @media only screen and (min-width: 2200px) {
    html, body {
      font-size: 18px;
    }

    @media only screen and (min-width: 2400px) {
      html, body {
        font-size: 19px;
      }

      @media only screen and (min-width: 2600px) {
        html, body {
          font-size: 20px;
        }

        @media only screen and (min-width: 2800px) {
          html, body {
            font-size: 22px;
          }

          @media only screen and (min-width: 3000px) {
            html, body {
              font-size: 24px;
            }

            @media only screen and (min-width: 3200px) {
              html, body {
                font-size: 26px;
              }

              @media only screen and (min-width: 3400px) {
                html, body {
                  font-size: 28px;
                }

                @media only screen and (min-width: 3600px) {
                  html, body {
                    font-size: 32px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-none {
  cursor: none;
}

.no-decoration {
  -webkit-tap-highlight-color: #0000;
  outline: none;
  text-decoration: none;
}

.overflow {
  overflow: hidden;
}

.width-overflow {
  width: 100%;
  overflow: hidden;
}

* {
  position: relative;
}

.only-mobile {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
  overflow: hidden;
}

@media only screen and (max-width: 850px) {
  .hide-mobile {
    opacity: 0;
    display: block;
    overflow: hidden;
    width: 0 !important;
    min-width: 0 !important;
    height: 0 !important;
    min-height: 0 !important;
    padding: 0 !important;
  }

  .only-mobile {
    overflow: initial;
    opacity: 1;
    width: auto;
    height: auto;
    position: relative;
  }

  @media only screen and (max-width: 500px) {
    .hide-small-mobile {
      opacity: 0;
      display: block;
      overflow: hidden;
      width: 0 !important;
      height: 0 !important;
      padding: 0 !important;
    }

    .only-small-mobile {
      overflow: initial;
      opacity: 1;
      width: auto;
      height: auto;
    }
  }
}

.center-holder {
  text-align: center;
}

.right-holder {
  text-align: right;
}

.left-holder {
  text-align: left;
}

.centered {
  margin: 0 auto;
}

.centered-content-120 {
  max-width: -webkit-calc(100% - 6em);
  width: 120em;
  max-width: calc(100% - 6em);
  margin: 0 auto;
}

.centered-content-75 {
  max-width: -webkit-calc(100% - 6em);
  width: 75em;
  max-width: calc(100% - 6em);
  margin: 0 auto;
}

.centered-content-55 {
  max-width: -webkit-calc(100% - 6em);
  width: 55em;
  max-width: calc(100% - 6em);
  margin: 0 auto;
}

.centered-content-40 {
  max-width: -webkit-calc(100% - 4em);
  width: 40em;
  max-width: calc(100% - 4em);
  margin: 0 auto;
}

.centered-inner-8 {
  max-width: -webkit-calc(100% - 16em);
  max-width: calc(100% - 16em);
  margin: 0 auto;
}

.centered-inner-4 {
  max-width: -webkit-calc(100% - 8em);
  max-width: calc(100% - 8em);
  margin: 0 auto;
}

@media only screen and (max-width: 850px) {
  .center-holder-mobile {
    text-align: center;
  }

  .centered-content-120 {
    max-width: -webkit-calc(100% - 4em);
    max-width: calc(100% - 4em);
  }

  .centered-content-75 {
    max-width: -webkit-calc(100% - 1em);
    width: 29em;
    max-width: calc(100% - 1em);
  }

  .centered-content-55, .centered-content-40 {
    max-width: -webkit-calc(100% - 1em);
    max-width: calc(100% - 1em);
  }

  .centered-inner-8, .centered-inner-4 {
    max-width: -webkit-calc(100% - 2em);
    max-width: calc(100% - 2em);
  }
}

.rel {
  position: relative;
}

.fixed {
  position: fixed;
  top: 0;
  left: 0;
}

.abs {
  position: absolute;
  top: 0;
  left: 0;
}

.abs.top-0-5em {
  top: .5em;
}

.abs.top--0-5em {
  top: -.5em;
}

.abs.top-10 {
  top: 10%;
  bottom: auto;
}

.abs.top-15 {
  top: 15%;
  bottom: auto;
}

.abs.top-20 {
  top: 20%;
  bottom: auto;
}

.abs.top-50 {
  top: 50%;
  bottom: auto;
}

.abs.top-100 {
  top: 100%;
  bottom: auto;
}

.abs.bot-0-5em {
  bottom: .5em;
}

.abs.bot--0-5em {
  bottom: -.5em;
}

.abs.bot, .fixed.bot {
  top: auto;
  bottom: 0;
}

.abs.full-fit {
  inset: 0;
}

.abs.bot-100 {
  top: auto;
  bottom: 100%;
}

.abs.left--3em {
  left: -3em;
}

.abs.left-0 {
  left: 0;
  right: auto;
}

.abs.left-10 {
  left: 10%;
  right: auto;
}

.abs.left-15 {
  left: 15%;
  right: auto;
}

.abs.left-20 {
  left: 20%;
  right: auto;
}

.abs.left-third {
  left: 33.3333%;
  left: -webkit-calc(100% / 3);
  right: auto;
}

.abs.left-50 {
  left: 50%;
  right: auto;
}

.abs.left-100 {
  left: 100%;
  right: auto;
}

.abs.right, .fixed.right {
  left: auto;
  right: 0;
}

.abs.right-15 {
  left: auto;
  right: 15%;
}

.abs.right-100 {
  left: auto;
  right: 100%;
}

.abs.top-bot {
  top: 0;
  bottom: 0;
}

.abs.left-right {
  left: 0;
  right: 0;
}

.line-height-1 {
  line-height: 1em;
}

.line-height-1-25 {
  line-height: 1.25em;
}

.line-height-2 {
  line-height: 2em;
}

.flexbox {
  display: flex;
}

.flexbox.column {
  flex-direction: column;
}

.flexbox.center {
  justify-content: center;
  align-items: center;
}

.flexbox.right {
  justify-content: right;
}

.flexbox.gap-1 {
  gap: 1em;
}

.flexbox.gap-1-5 {
  gap: 1.5em;
}

.flexbox.gap-h-3 {
  gap: 0 3em;
}

.flexbox.gap-h-2 {
  gap: 0 2em;
}

.flexbox.gap-h-1-5 {
  gap: 0 1.5em;
}

.flexbox.gap-h-1 {
  gap: 0 1em;
}

.flexbox.gap-h-0-75 {
  gap: 0 .75em;
}

.flexbox.gap-h-0-5 {
  gap: 0 .5em;
}

.flexbox.gap-v-0-25 {
  gap: .25em 0;
}

.flexbox.gap-v-1 {
  gap: 1em 0;
}

.flexbox.gap-v-2 {
  gap: 2em 0;
}

.flexbox .flex {
  flex: 1;
}

.flexbox .flex-2 {
  flex: 2;
}

.flexbox .align-right {
  align-self: right;
}

.flexbox .order-first {
  order: -1;
}

.flexbox .order-last {
  order: 99;
}

.clear {
  clear: both;
  display: block;
}

.clear-after:after {
  content: "";
  clear: both;
  display: block;
}

.float {
  float: left;
}

.float.right {
  float: right;
}

.middle-holder {
  width: 100%;
  height: 100%;
  display: table;
}

.middle-holder .middle {
  vertical-align: middle;
  width: 100%;
  display: table-cell;
}

.svg-v-align-top svg {
  vertical-align: top;
}

.svg-v-align-bot svg, .v-align-bot {
  vertical-align: bottom;
}

.v-align-top {
  vertical-align: top;
}

.v-align-middle, .v-align-middle-childs > * {
  vertical-align: middle;
}

.bg-position-top {
  object-position: center top;
}

.bg-position-center-top {
  object-position: center top;
  background-position: top;
}

.bg-position-center {
  object-position: center;
  background-position: center;
}

.bg-cover {
  object-fit: cover;
  background-size: cover;
}

.bg-contain {
  object-fit: contain;
  background-size: contain;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

@media only screen and (max-width: 850px) {
  .rel-mobile, .open .open-parent-rel-mobile {
    position: relative;
    inset: auto !important;
  }

  .abs-mobile {
    position: absolute;
  }

  .abs-mobile.bot-0-mobile, .abs.bot-0-mobile {
    top: auto;
    bottom: 0;
  }

  .abs-mobile.top-0-mobile, .abs.top-0-mobile {
    top: 0;
    bottom: auto;
  }

  .abs-mobile.top-100-mobile, .abs.top-100-mobile {
    top: 100%;
    bottom: auto;
  }

  .abs-mobile.left-0-mobile, .abs.left-0-mobile {
    left: 0;
    right: auto;
  }

  .abs-mobile.right-0-mobile, .abs.right-0-mobile {
    left: auto;
    right: 0;
  }

  .abs-mobile.left-auto-mobile, .abs.left-auto-mobile {
    bottom: auto;
    left: auto;
  }

  .float-none-mobile {
    float: none !important;
  }

  .flexbox-mobile {
    display: flex;
  }

  .flexbox-mobile.column {
    flex-direction: column;
  }

  .flexbox-mobile.center {
    justify-content: center;
    align-items: center;
  }

  .flexbox.wrap-mobile, .flexbox-mobile.wrap-mobile {
    flex-wrap: wrap;
  }

  .flexbox.column-mobile {
    flex-direction: column;
  }

  .flexbox.gap-0-5-mobile, .flexbox-mobile.gap-0-5-mobile {
    gap: .5em;
  }

  .flexbox.gap-1-mobile {
    gap: 1em;
  }

  .flexbox.gap-h-1-mobile {
    gap: 0 1em;
  }

  .flexbox.gap-1-5-mobile {
    gap: 1.5em;
  }

  .flexbox.gap-2-mobile {
    gap: 2em;
  }

  .flexbox.gap-h-2-mobile {
    gap: 0 2em;
  }

  .nowrap-mobile {
    white-space: nowrap;
  }

  .flexbox-mobile .break-mobile, .flexbox .break-mobile {
    flex-basis: 100%;
  }

  .flexbox.left-mobile {
    align-items: normal;
  }

  .flexbox .noflex-mobile {
    flex: none;
  }
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-3 {
  z-index: 3;
}

.z-index-4 {
  z-index: 4;
}

.z-index-5 {
  z-index: 5;
}

.z-index-6 {
  z-index: 6;
}

.z-index-7 {
  z-index: 7;
}

.z-fg {
  z-index: 10;
}

.no-events {
  pointer-events: none;
}

.active-events.active, .open .open-parent-events, .spe-parent.open .open-spe-parent-events {
  pointer-events: all;
}

.no-select {
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: #0000;
}

@media only screen and (max-width: 850px) {
  .no-events-mobile {
    pointer-events: none;
  }

  .open .open-parent-events-mobile, .open-events-mobile.open {
    pointer-events: all;
  }
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.border-box {
  box-sizing: border-box;
}

.height-1px {
  height: 1px;
}

.height-2px {
  height: 2px;
}

.height-3px {
  height: 3px;
}

.height-50 {
  height: 50%;
}

.height-100 {
  height: 100%;
}

.height-0-25em {
  height: .25em;
}

.height-0-35em {
  height: .35em;
}

.height-0-5em {
  height: .5em;
}

.height-0-625em {
  height: .625em;
}

.height-0-75em {
  height: .75em;
}

.height-checkbox {
  height: .8125em;
}

.height-1em {
  height: 1em;
}

.height-1-25em {
  height: 1.25em;
}

.height-1-375em {
  height: 1.375em;
}

.height-1-5em {
  height: 1.5em;
}

.height-1-75em {
  height: 1.75em;
}

.height-2em {
  height: 2em;
}

.height-2-25em {
  height: 2.25em;
}

.height-2-375em {
  height: 2.375em;
}

.height-2-5em {
  height: 2.5em;
}

.height-3em {
  height: 3em;
}

.height-4em {
  height: 4em;
}

.height-4-5em {
  height: 4.5em;
}

.height-5em {
  height: 5em;
}

.height-7em {
  height: 7em;
}

.height-8em {
  height: 8em;
}

.height-10em {
  height: 10em;
}

.height-16em {
  height: 16em;
}

.height-60 {
  height: 60%;
}

.height-70 {
  height: 70%;
}

.height-80 {
  height: 80%;
}

.height-100vh {
  height: 100vh;
}

.min-height-3em {
  min-height: 3em;
}

.min-height-4em {
  min-height: 4em;
}

.min-height-26em {
  min-height: 26em;
}

.min-height-100vh {
  min-height: 100vh;
}

.max-height-30em {
  max-height: 30em;
}

.max-height-80vh {
  max-height: 80vh;
}

.width-third {
  width: -webkit-calc(100% / 3);
  width: 33.3333%;
}

.width-0 {
  width: 0;
}

.width-20 {
  width: 20%;
}

.width-25 {
  width: 25%;
}

.width-50 {
  width: 50%;
}

.width-55 {
  width: 55%;
}

.width-100 {
  width: 100%;
}

.width-110 {
  width: 110%;
}

.max-width-50 {
  max-width: 50%;
}

.max-width-100 {
  max-width: 100%;
}

.width-1px {
  width: 1px;
}

.width-0-25em {
  width: .25em;
}

.width-0-3125em {
  width: .3125em;
}

.width-0-5em {
  width: .5em;
}

.width-checkbox {
  width: .8125em;
}

.width-1em {
  width: 1em;
}

.width-1-1em {
  width: 1.1em;
}

.width-1-25em {
  width: 1.25em;
}

.width-1-5em {
  width: 1.5em;
}

.width-2em {
  width: 2em;
}

.width-2-25em {
  width: 2.25em;
}

.width-2-35em {
  width: 2.35em;
}

.width-2-5em {
  width: 2.5em;
}

.width-3em {
  width: 3em;
}

.width-4em {
  width: 4em;
}

.width-4-5em {
  width: 4.5em;
}

.width-5em {
  width: 5em;
}

.width-6em {
  width: 6em;
}

.width-8em {
  width: 8em;
}

.width-10em {
  width: 10em;
}

.width-11em {
  width: 11em;
}

.width-13em {
  width: 13em;
}

.width-menu, .width-15em {
  width: 15em;
}

.max-width-15em {
  max-width: 15em;
}

.width-20em {
  width: 20em;
}

.width-25em {
  width: 25em;
}

.width-login-col, .width-31em {
  width: 31em;
}

.width-50em {
  width: 50em;
}

.width-90em {
  width: 90em;
}

.width-100em {
  width: 100em;
}

.width-500em {
  width: 500em;
}

.width-60 {
  width: 60%;
}

.width-70 {
  width: 70%;
}

.width-80 {
  width: 80%;
}

.view-size {
  min-width: 55em;
  height: 85%;
  overflow: auto;
}

.transition-width {
  transition: width .25s;
}

.svg-fit-height, .svg-fit-width {
  line-height: 0;
}

.svg-fit-height svg {
  vertical-align: middle;
  height: 100%;
}

.svg-fit-width svg {
  vertical-align: middle;
  width: 100%;
}

.width-planning-left {
  width: 22.5em;
}

.height-planning-head {
  height: 2em;
}

@media only screen and (max-width: 850px) {
  .block-mobile {
    display: block;
  }

  .inline-block-mobile {
    display: inline-block;
  }

  .width-2em-mobile {
    width: 2em;
  }

  .width-3em-mobile {
    width: 3em;
  }

  .width-7em-mobile {
    width: 7em;
  }

  .width-11em-mobile {
    width: 11em;
  }

  .width-80em-mobile {
    width: 80em;
  }

  .width-35em-mobile {
    width: 35em;
  }

  .max-width-20em-mobile {
    max-width: 20em;
  }

  .max-width-28em-mobile {
    max-width: 28em;
  }

  .max-width-100-mobile {
    max-width: 100%;
  }

  .width-100-mobile {
    width: 100%;
  }

  .width-auto-mobile {
    width: auto;
  }

  .height-auto-mobile {
    height: auto;
  }

  .height-1px-mobile {
    height: 1px;
  }

  .height-0-5em-mobile {
    height: .5em;
  }

  .height-2em-mobile {
    height: 2em;
  }

  .height-3em-mobile {
    height: 3em;
  }

  .min-height-5em-mobile {
    min-height: 5em;
  }

  .height-4em-mobile {
    height: 4em;
  }

  .height-7em-mobile {
    height: 7em;
  }

  .height-13em-mobile {
    height: 13em;
  }

  .height-15em-mobile {
    height: 15em;
  }

  .height-18em-mobile {
    height: 18em;
  }

  .height-28em-mobile {
    height: 28em;
  }

  .height-60vh-mobile {
    height: 60vh;
  }

  .height-planning-line-mobile {
    height: 2.5em;
  }

  .open.open-height-100-mobile {
    height: 100%;
  }

  .svg-height-auto-mobile svg {
    height: auto;
  }
}

.padding-0 {
  padding: 0;
}

.padding-0-25 {
  padding: .25em;
}

.padding-0-5 {
  padding: .5em;
}

.padding-0-75 {
  padding: .75em;
}

.padding-1, .active-padding-1.active {
  padding: 1em;
}

.padding-1-5 {
  padding: 1.5em;
}

.padding-2 {
  padding: 2em;
}

.left-pad-0-1 {
  padding-left: .1em;
}

.left-pad-0-25 {
  padding-left: .25em;
}

.left-pad-0-5 {
  padding-left: .5em;
}

.left-pad-0-75 {
  padding-left: .75em;
}

.left-pad-1 {
  padding-left: 1em;
}

.left-pad-1-5 {
  padding-left: 1.5em;
}

.left-pad-1-75 {
  padding-left: 1.75em;
}

.left-pad-2 {
  padding-left: 2em;
}

.left-pad-2-25 {
  padding-left: 2.25em;
}

.left-pad-2-5 {
  padding-left: 2.5em;
}

.left-pad-3 {
  padding-left: 3em;
}

.left-pad-4 {
  padding-left: 4em;
}

.left-pad-5 {
  padding-left: 5em;
}

.left-pad-7 {
  padding-left: 7em;
}

.left-pad-menu {
  padding-left: 15em;
}

.right-pad-0-15 {
  padding-right: .15em;
}

.right-pad-0-25 {
  padding-right: .25em;
}

.right-pad-0-5 {
  padding-right: .5em;
}

.right-pad-0-75 {
  padding-right: .75em;
}

.right-pad-1 {
  padding-right: 1em;
}

.right-pad-1-5 {
  padding-right: 1.5em;
}

.right-pad-2 {
  padding-right: 2em;
}

.right-pad-3 {
  padding-right: 3em;
}

.right-pad-4 {
  padding-right: 4em;
}

.right-pad-5 {
  padding-right: 5em;
}

.top-pad-0-25 {
  padding-top: .25em;
}

.top-pad-0-5 {
  padding-top: .5em;
}

.top-pad-0-6 {
  padding-top: .6em;
}

.top-pad-0-75 {
  padding-top: .75em;
}

.top-pad-1 {
  padding-top: 1em;
}

.top-pad-1-5 {
  padding-top: 1.5em;
}

.top-pad-2 {
  padding-top: 2em;
}

.top-pad-3 {
  padding-top: 3em;
}

.top-pad-4 {
  padding-top: 4em;
}

.top-pad-3-5 {
  padding-top: 3.5em;
}

.top-pad-5 {
  padding-top: 5em;
}

.top-pad-7 {
  padding-top: 7em;
}

.top-pad-10 {
  padding-top: 10em;
}

.top-pad-20 {
  padding-top: 20em;
}

.bot-pad-0-25 {
  padding-bottom: .25em;
}

.bot-pad-0-5 {
  padding-bottom: .5em;
}

.bot-pad-0-75 {
  padding-bottom: .75em;
}

.bot-pad-1 {
  padding-bottom: 1em;
}

.bot-pad-1-5 {
  padding-bottom: 1.5em;
}

.bot-pad-2 {
  padding-bottom: 2em;
}

.bot-pad-3 {
  padding-bottom: 3em;
}

.bot-pad-3-5 {
  padding-bottom: 3.5em;
}

.bot-pad-4 {
  padding-bottom: 4em;
}

.bot-pad-5 {
  padding-bottom: 5em;
}

.bot-pad-10 {
  padding-bottom: 10em;
}

.bot-pad-20 {
  padding-bottom: 20em;
}

.padding-planning {
  padding-left: 2em;
  padding-right: 2em;
}

.margin-0 {
  margin: 0;
}

.left-margin-auto {
  margin-left: auto;
}

.left-margin--1px {
  margin-left: -1px;
}

.left-margin--0-125 {
  margin-left: -.125em;
}

.left-margin--0-5 {
  margin-left: -.5em;
}

.left-margin--0-75 {
  margin-left: -.75em;
}

.left-margin--1 {
  margin-left: -1em;
}

.left-margin--1-25 {
  margin-left: -1.25em;
}

.left-margin--1-5 {
  margin-left: -1.5em;
}

.left-margin--2 {
  margin-left: -2em;
}

.left-margin--2-25 {
  margin-left: -2.25em;
}

.left-margin--2-5 {
  margin-left: -2.5em;
}

.left-margin--4 {
  margin-left: -4em;
}

.left-margin--6-5 {
  margin-left: -6.5em;
}

.left-margin--18 {
  margin-left: -18em;
}

.left-margin--25 {
  margin-left: -25em;
}

.left-margin--45 {
  margin-left: -45em;
}

.left-margin--50 {
  margin-left: -50em;
}

.left-margin--5p {
  margin-left: -5%;
}

.left-margin-1px {
  margin-left: 1px;
}

.left-margin-0-125 {
  margin-left: .125em;
}

.left-margin-0-5 {
  margin-left: .5em;
}

.left-margin-0-75 {
  margin-left: .75em;
}

.left-margin-1 {
  margin-left: 1em;
}

.left-margin-1-5 {
  margin-left: 1.5em;
}

.left-margin-2 {
  margin-left: 2em;
}

.right-margin-auto {
  margin-right: auto;
}

.right-margin--1px {
  margin-right: -1px;
}

.right-margin--0-5 {
  margin-right: -.5em;
}

.right-margin--1 {
  margin-right: -1em;
}

.right-margin--2 {
  margin-right: -2em;
}

.right-margin-0-5 {
  margin-right: .5em;
}

.right-margin-0-75 {
  margin-right: .75em;
}

.right-margin-1 {
  margin-right: 1em;
}

.right-margin-1-5 {
  margin-right: 1.5em;
}

.right-margin-2 {
  margin-right: 2em;
}

.right-margin-2-5 {
  margin-right: 2.5em;
}

.right-margin-3 {
  margin-right: 3em;
}

.right-margin-4 {
  margin-right: 4em;
}

.right-margin-4-25 {
  margin-right: 4.25em;
}

.top-margin--1px {
  margin-top: -1px;
}

.top-margin--0-05 {
  margin-top: -.05em;
}

.top-margin--0-1 {
  margin-top: -.1em;
}

.top-margin--0-175 {
  margin-top: -.175em;
}

.top-margin--0-2 {
  margin-top: -.2em;
}

.top-margin--0-25 {
  margin-top: -.25em;
}

.top-margin--0-375 {
  margin-top: -.375em;
}

.top-margin--0-5 {
  margin-top: -.5em;
}

.top-margin--0-625 {
  margin-top: -.625em;
}

.top-margin--0-75 {
  margin-top: -.75em;
}

.top-margin--1 {
  margin-top: -1em;
}

.top-margin--2 {
  margin-top: -2em;
}

.top-margin--5-5 {
  margin-top: -5.5em;
}

.top-margin-0-15 {
  margin-top: .15em;
}

.top-margin-0-25 {
  margin-top: .25em;
}

.top-margin-0-375 {
  margin-top: .375em;
}

.top-margin-0-5 {
  margin-top: .5em;
}

.top-margin-0-75 {
  margin-top: .75em;
}

.top-margin-1, .active.active-top-margin-1 {
  margin-top: 1em;
}

.top-margin-1-25 {
  margin-top: 1.25em;
}

.top-margin-1-5 {
  margin-top: 1.5em;
}

.top-margin-2 {
  margin-top: 2em;
}

.top-margin-2-5 {
  margin-top: 2.5em;
}

.top-margin-3 {
  margin-top: 3em;
}

.top-margin-3-5 {
  margin-top: 3.5em;
}

.top-margin-4 {
  margin-top: 4em;
}

.top-margin-5 {
  margin-top: 5em;
}

.top-margin-6 {
  margin-top: 6em;
}

.top-margin-7 {
  margin-top: 7em;
}

.top-margin-10 {
  margin-top: 10em;
}

.bot-margin-0-5 {
  margin-bottom: .5em;
}

.bot-margin-1, .active.active-bot-margin-1 {
  margin-bottom: 1em;
}

.bot-margin-1-25 {
  margin-bottom: 1.25em;
}

.bot-margin-2, .active.active-bot-margin-2 {
  margin-bottom: 2em;
}

.bot-margin-2-25 {
  margin-bottom: 2.25em;
}

.bot-margin-5 {
  margin-bottom: 5em;
}

.bot-margin-6-25 {
  margin-bottom: 6.25em;
}

.bot-margin-6-5 {
  margin-bottom: 6.5em;
}

.bot-margin-8 {
  margin-bottom: 8em;
}

.bot-margin--1px {
  margin-bottom: -1px;
}

.bot-margin--0-5 {
  margin-bottom: -.5em;
}

.bot-margin--1 {
  margin-bottom: -1em;
}

.bot-margin--2 {
  margin-bottom: -2em;
}

@media only screen and (max-width: 850px) {
  .margin-auto-mobile {
    margin: auto;
  }

  .right-margin-auto-mobile {
    margin-right: auto;
  }

  .right-margin--1-mobile {
    margin-right: -1em;
  }

  .right-margin-1-5-mobile {
    margin-right: 1.5em;
  }

  .right-margin--2-mobile {
    margin-right: -2em;
  }

  .left-margin-auto-mobile {
    margin-left: auto;
  }

  .left-margin-0-mobile {
    margin-left: 0;
  }

  .left-margin-1-mobile {
    margin-left: 1em;
  }

  .left-margin--1-mobile {
    margin-left: -1em;
  }

  .left-margin--2-mobile {
    margin-left: -2em;
  }

  .left-margin--5-mobile {
    margin-left: -5em;
  }

  .left-margin--7-mobile {
    margin-left: -7em;
  }

  .left-margin--17-5-mobile {
    margin-left: -17.5em;
  }

  .left-margin--40-mobile {
    margin-left: -40em;
  }

  .top-margin--3-5-mobile {
    margin-top: -3.5em;
  }

  .top-margin-0-mobile {
    margin-top: 0;
  }

  .top-margin-1-mobile {
    margin-top: 1em;
  }

  .top-margin-1-5-mobile {
    margin-top: 1.5em;
  }

  .top-margin-2-mobile {
    margin-top: 2em;
  }

  .bot-margin--0-5-mobile {
    margin-bottom: -.5em;
  }

  .left-pad-0-mobile {
    padding-left: 0;
  }

  .left-pad-0-75-mobile {
    padding-left: .75em;
  }

  .left-pad-1-mobile {
    padding-left: 1em;
  }

  .left-pad-2-mobile {
    padding-left: 2em;
  }

  .left-pad-3-mobile {
    padding-left: 3em;
  }

  .right-pad-0-mobile {
    padding-right: 0;
  }

  .right-pad-0-75-mobile {
    padding-right: .75em;
  }

  .right-pad-1-mobile {
    padding-right: 1em;
  }

  .right-pad-2-mobile {
    padding-right: 2em;
  }

  .padding-0-mobile {
    padding: 0;
  }

  .top-pad-0-mobile {
    padding-top: 0;
  }

  .top-pad-0-25-mobile {
    padding-top: .25em;
  }

  .top-pad-0-5-mobile {
    padding-top: .5em;
  }

  .top-pad-1-mobile {
    padding-top: 1em;
  }

  .top-pad-2-mobile {
    padding-top: 2em;
  }

  .top-pad-3-mobile {
    padding-top: 3em;
  }

  .top-pad-5-mobile {
    padding-top: 5em;
  }

  .top-pad-6-mobile {
    padding-top: 6em;
  }

  .top-pad-menu-mobile {
    padding-top: 4em;
  }

  .bot-pad-0-mobile {
    padding-bottom: 0;
  }

  .bot-pad-0-25-mobile {
    padding-bottom: .25em;
  }

  .bot-pad-0-5-mobile {
    padding-bottom: .5em;
  }

  .bot-pad-1-mobile {
    padding-bottom: 1em;
  }

  .bot-pad-1-5-mobile {
    padding-bottom: 1.5em;
  }

  .bot-pad-2-mobile {
    padding-bottom: 2em;
  }

  .bot-pad-3-mobile {
    padding-bottom: 3em;
  }

  .bot-pad-6-mobile {
    padding-bottom: 6em;
  }
}

.pdf {
  box-sizing: border-box;
  padding: 3em;
}

.btn {
  box-sizing: border-box;
  cursor: pointer;
  vertical-align: middle;
  color: #fff;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: #0000;
  background-color: #0000;
  border: none;
}

.btn:not(.flexbox) {
  display: inline-block;
}

.btn.primary {
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid #0000;
  border-radius: .5em;
  padding: .725em 1em;
  font-size: 112.5%;
  font-weight: 700;
  transition: background-color .25s, color .25s, border-color .25s;
}

.btn.small {
  -webkit-appearance: none;
  appearance: none;
  border-radius: .5em;
  padding: .5em 1em;
  font-size: 100%;
  font-weight: 700;
  transition: background-color .25s, color .25s, border-color .25s;
}

.btn.rehausse {
  color: #fff;
  background-color: #2b50e8;
}

.btn.rehausse:hover {
  background-color: #1333b3;
}

.btn.lightrehausse {
  color: #020b59;
  background-color: #d9e0fb;
}

.btn.lightrehausse:hover {
  background-color: #c2cdf9;
}

.btn.lightrehausse svg path {
  fill: #020b59 !important;
}

.btn.darkblue {
  color: #fff;
  background-color: #020b59;
}

.btn.darkblue:hover {
  background-color: #010527;
}

.btn.bluegrey {
  color: #bbc3d2;
}

.btn.bluegrey:hover {
  color: #2b50e8;
}

.btn.bluegrey svg path {
  fill: #bbc3d2 !important;
}

.btn.bluegrey:hover svg path {
  fill: #2b50e8 !important;
}

.btn.white {
  color: #68717d;
  background-color: #fff;
  border: 1px solid #e5e5e5;
}

.btn.white:hover {
  background-color: #e5e5e5;
}

.btn.yellow {
  color: #000;
  background-color: #fbce2f;
}

.btn.yellow:hover {
  color: #fbce2f;
  background-color: #000;
}

.btn.activable {
  color: #fff;
  cursor: initial;
  background-color: #bbc3d2;
}

.btn.activable.active {
  cursor: pointer;
  background-color: #2b50e8;
}

.btn.activable.active:hover {
  background-color: #1333b3;
}

.btn.toggleable {
  color: #2b50e8;
  cursor: pointer;
  background-color: #fff;
  border-color: #2b50e8;
}

.btn.toggleable svg path {
  fill: #2b50e8 !important;
}

.btn.toggleable.active {
  color: #fff;
  cursor: pointer;
  background-color: #2b50e8;
}

.btn.toggleable.active svg path {
  fill: #fff !important;
}

.btn.pending {
  pointer-events: none;
  padding-right: 2.75em;
  background-color: gray !important;
}

.btn.pending:after {
  content: "";
  box-sizing: border-box;
  border: .2em solid #fff;
  border-bottom-color: #0000;
  border-radius: 50%;
  width: 1em;
  height: 1em;
  margin-top: -.5em;
  animation: 1s linear infinite rotate;
  display: block;
  position: absolute;
  top: 50%;
  right: 1em;
}

.btn.icon {
  padding-right: 2.15em;
}

.btn.icon .icon-holder {
  width: 1em;
  height: 1.25em;
  margin: -.65em .6em 0 0;
  display: flex;
  position: absolute;
  top: 50%;
  right: 0;
}

.btn.icon .icon-holder svg path {
  transition: fill .25s;
}

.btn.icon.white svg path {
  fill: #68717d;
}

.btn.burger {
  padding: 0;
}

.btn.burger .content {
  display: none;
}

.btn.burger .icon-holder {
  width: 2.25em;
  height: 2.25em;
  margin: 0;
  display: flex;
  position: relative;
  top: auto;
  right: auto;
}

.btn.burger .icon-holder svg .top, .btn.burger .icon-holder svg .mid, .btn.burger .icon-holder svg .bot {
  transform-box: fill-box;
  transform-origin: center;
}

.btn.burger.active .icon-holder svg .top {
  animation: .25s ease-out both down-rotate;
}

.btn.burger.active .icon-holder svg .bot {
  animation: .25s ease-out both up-rotate;
}

.btn.burger.active .icon-holder svg .mid {
  animation: .25s ease-out forwards hide;
}

@media only screen and (max-width: 850px) {
  .btn.icon:not(.burger) {
    padding: .5em;
  }

  .btn.icon:not(.burger) .content {
    display: none;
  }

  .btn.icon:not(.burger) .icon-holder {
    width: 1em;
    height: 1.25em;
    margin: 0;
    padding: 0 .125em;
    display: flex;
    position: relative;
    top: auto;
    right: auto;
  }
}

input, textarea, select {
  font-size: inherit;
  font-style: inherit;
  font-family: inherit;
  font-weight: inherit;
  resize: none;
}

.fake-input {
  -webkit-appearance: none;
  appearance: none;
  opacity: 0;
  background-color: #0000;
  border: none;
  outline: none;
  width: 1px;
  height: 1px;
  margin: 0;
  padding: 0;
}

.form .field .item + .item {
  margin-top: .75em;
}

.form .field.combo > label, .form .field.select > label, .form .field.textarea > label, .form .field.rich > label, .form .field.color > label, .form .field.file > label, .form .field.text > label {
  text-transform: uppercase;
  letter-spacing: .08em;
  font-weight: 500;
  line-height: 1.1718em;
  display: block;
}

.form .field.text > label + .items-container, .form .field.text > label + .items-container, .form .field.color > label + .items-container, .form .field.combo > label + .items-container, .form .field.textarea > label + .items-container, .form .field.file > label + .items-container, .form .field.select > label + .items-container {
  margin-top: .5em;
}

.form .field.select select {
  cursor: pointer;
}

.form .field.text textarea, .form .field.select select, .form .field.textarea textarea, .form .field.text input {
  box-sizing: border-box;
  color: inherit;
  background-color: #fff;
  border: 1px solid #bbc3d2;
  border-radius: .5em;
  outline: none;
  width: 100%;
  height: 2.88em;
  padding: 0 .75em;
  line-height: 2.88em;
  transition: border-color .25s;
}

.form .field.textarea textarea {
  height: auto;
  padding: .75em;
  line-height: 1.17em;
}

.form .field.text.editable input {
  line-height: inherit;
  border-radius: initial;
  background-color: #0000;
  border: none;
  height: auto;
  padding: 0;
}

.form .field.text.editable.active input {
  background-color: #ffffff4d;
  border-radius: .25em;
  padding: 0 .25em;
}

.form .field.text input::placeholder, .form .field.text textarea::placeholder {
  color: #032663;
  opacity: .3;
  font-style: italic;
}

.form .field.text textarea::placeholder {
  color: #032663;
  opacity: .3;
  font-style: italic;
}

.form .field.text textarea::-moz-placeholder {
  color: #032663;
  opacity: .3;
  font-style: italic;
}

.form .field.radio + .field.radio {
  margin-top: 1.5em;
}

.form .field.radio label {
  font-size: 125%;
}

.form .field.radio .options {
  margin-top: .75em;
}

.form .field.radio .options .option {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  display: inline-block;
}

.form .field.radio .options .option + .option {
  margin-left: 1em;
}

.form .field.radio .options .option label {
  vertical-align: middle;
  cursor: inherit;
  color: #495057;
  font-size: 112.5%;
}

.form .field.radio .options .option input {
  vertical-align: middle;
  -webkit-appearance: none;
  appearance: none;
  cursor: inherit;
  border: 1px solid #dde2e5;
  border-radius: 50%;
  width: 1.125em;
  height: 1.125em;
  margin: 0 .25em 0 0;
  padding: 0;
}

.form .field.radio .options .option input:checked {
  border: 4px solid #4263eb;
}

.form .field.radio .options .option input:checked + label {
  font-weight: bold;
}

.form .field.check .item > label {
  color: #495057;
  cursor: pointer;
  padding-left: 1.5em;
  font-size: 112.5%;
  font-weight: 700;
  display: inline-block;
}

.form .field.check input {
  cursor: pointer;
  width: 1em;
  height: 1em;
  margin: -.5em 0 0;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 0;
}

.form .field {
  display: inline-block;
}

.form .field input[type="date"]::-webkit-calendar-picker-indicator {
  background-image: none;
}

.form .field input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  background-image: none;
}

.form .field.file input[type="file"] {
  opacity: 0;
  -webkit-appearance: none;
  appearance: none;
  pointer-events: none;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

.form .field.file > label + .input-container {
  margin-top: .5em;
}

.form .field.file .input-container {
  z-index: 4;
  border: 1px solid #0000;
  border-radius: .5em;
  outline: none;
  justify-content: center;
  align-items: center;
  gap: .5em;
  margin-top: .5em;
  transition: border-radius .15s;
  display: flex;
}

.form .field.file .input-container .left-part {
  line-height: inherit;
  box-sizing: border-box;
  height: 100%;
  padding: 0 .75em 0 0;
  transition: padding .25s;
}

.form .field.file .input-container .left-part label {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid #0000;
  padding: .5em .75em;
  font-size: 100%;
  line-height: 1em;
  display: inline-block;
}

.form .field.file .input-container .image-container {
  cursor: pointer;
  z-index: 2;
  box-sizing: border-box;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #bbc3d2;
  border-radius: 50%;
  width: 2.88em;
  height: 2.88em;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.form .field.file .input-container .image-container .content {
  width: 100%;
  height: 100%;
}

.form .field.file .input-container .image-container .content img {
  vertical-align: middle;
  object-fit: cover;
  object-position: center;
  box-sizing: border-box;
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
}

.form .field.file .input-container .text-container {
  flex: 1;
}

.form .field.file.gallery .items-container {
  gap: 0 2em;
  display: flex;
}

.form .field.file.gallery .item {
  margin-top: 0;
}

.form .field.file.gallery .input-container {
  flex-direction: column;
  margin-top: 0;
}

.form .field.file.gallery .input-container .left-part {
  padding: 0;
}

.form .field.file.gallery .input-container .image-container {
  border-radius: .5em;
  width: auto;
  height: 10em;
}

.form .field.file.gallery .input-container .image-container .content, .form .field.file.gallery .input-container .image-container .content img {
  width: auto;
  height: 100%;
}

.form .field.file .item.empty .input-container .image-container img {
  opacity: 0;
}

.form .field.color > label + .input-container {
  margin-top: .5em;
}

.form .field.color .input-container {
  z-index: 4;
  background-color: #fff;
  border: 1px solid #bbc3d2;
  border-radius: .5em;
  outline: none;
  height: 2.88em;
  margin-top: .5em;
  line-height: 2.88em;
  transition: border-radius .15s;
  display: flex;
}

.form .field.color .input-container .color-container {
  z-index: 2;
  box-sizing: border-box;
  border: 1px solid #bbc3d2;
  border-radius: 1em;
  width: 1.5em;
  height: 1.5em;
  margin: -.75em 0 0 .75em;
  padding: 0;
  position: absolute;
  top: 50%;
  overflow: hidden;
}

.form .field.color .input-container .color-container input[type="color"] {
  cursor: pointer;
  border: none;
  width: 300%;
  height: 300%;
  margin: 0;
  padding: 0;
  position: absolute;
  top: -100%;
  left: -100%;
}

.form .field.color input.hexa-value {
  z-index: 1;
  box-sizing: border-box;
  color: inherit;
  background-color: #0000;
  border: none;
  border-radius: .5em;
  width: 100%;
  height: 100%;
  padding: 0 .75em 0 3em;
  line-height: 2.88em;
}

.form .field.combo .input-container {
  z-index: 4;
  cursor: pointer;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #bbc3d2;
  border-radius: .5em;
  outline: none;
  min-width: 10em;
  height: 2.88em;
  line-height: 2.88em;
  transition: border-radius .15s, border-color .25s;
  display: flex;
}

.form .field.combo label + .input-container {
  margin-top: .5em;
}

.form .field.combo .selection {
  line-height: inherit;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 .3em;
  transition: padding .25s;
  display: flex;
}

.form .field.combo .selection:empty {
  padding: 0;
}

.form .field.combo .datalist {
  pointer-events: none;
  opacity: 0;
  z-index: 10;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #bbc3d2;
  border-top: none;
  border-bottom-right-radius: .5em;
  border-bottom-left-radius: .5em;
  width: 100%;
  max-height: 0;
  padding: 0;
  transition: max-height .25s, padding .25s, opacity .25s;
  position: absolute;
  top: 100%;
  overflow: hidden;
}

.form .field.combo .datalist .content {
  max-height: 10em;
  margin-top: .25em;
  overflow: clip auto;
}

.form .field.combo .datalist .search-zone input {
  opacity: .6;
  box-sizing: border-box;
  color: inherit;
  background-color: #f0f7fc;
  border: none;
  border-radius: .25em;
  outline: none;
  width: 100%;
  height: 2.25em;
  padding: 0 .75em 0 2.25em;
  line-height: 2.25em;
  transition: height .25s;
}

.form .field.combo-select.nosearch .datalist .search-zone {
  opacity: 0;
  width: 1px;
  position: absolute;
  top: 0;
  left: 50%;
}

.form .field.combo-select.nosearch .datalist .search-zone input {
  height: 1px;
  padding: 0;
}

.form .field.combo.open .datalist {
  opacity: 1;
  pointer-events: auto;
  max-height: 100vh;
  padding: .25em;
}

.form .field.combo:not(.open) .datalist .search-zone input {
  height: 1px;
}

.form .field.combo.open .input-container {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form .field.combo .selection .option, .form .field.combo .datalist .option {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid #0000;
  padding: .5em;
  font-size: 100%;
  line-height: 1em;
  display: inline-block;
}

.form .field.combo .datalist .option + .option {
  margin-top: .5em;
}

.form .field.combo-std .selection .option, .form .field.combo-select .selection .option, .form .field.combo-std .datalist .option:hover, .form .field.combo-select .datalist .option:hover {
  border-color: #bbc3d2;
  border-radius: .5em;
}

.form .field.combo-select.simple .selection .option {
  border-color: #0000;
  border-radius: 0;
}

.form .field.combo-std .selection .option + .option, .form .field.combo-select .selection .option + .option {
  margin-left: .25em;
}

.form .field.combo-std .selection .option .remove, .form .field.combo-select .selection .option .remove {
  padding-left: .5em;
  transition: color .25s;
}

.form .field.combo-std .selection .option .remove:hover, .form .field.combo-select .selection .option .remove:hover {
  color: #ff656c;
}

.form .field.combo-check .input-container, .form .field.combo-select .input-container {
  padding-right: 3em;
}

.form .field.combo-check .zone, .form .field.combo-select .zone {
  cursor: pointer;
}

.form .field.combo-check .selection {
  display: none;
}

.form .field.combo-check .selection-count {
  -webkit-user-select: none;
  user-select: none;
  min-width: 8em;
  padding-left: .75em;
}

.form .field.combo-check .datalist .option label {
  cursor: pointer;
}

.form .field.lined {
  display: flex;
}

.form .field.lined > label {
  text-transform: none;
  font-weight: initial;
  letter-spacing: initial;
  padding-right: .75em;
}

.form .field.lined > label, .form .field.lined > label + .items-container, .form .field.lined .item .input-container {
  margin-top: 0;
}

.form .field.rich .input-container {
  z-index: 4;
  background-color: #fff;
  border: 1px solid #bbc3d2;
  border-radius: .5em;
  outline: none;
  margin-top: .5em;
  padding: .75em;
  transition: border-radius .15s;
}

.form .field.rich .input-container > div {
  line-height: 1.17em;
}

.ck.ck-balloon-panel {
  box-shadow: none !important;
  border-radius: .5em .5em 0 0 !important;
}

.ck.ck-balloon-panel .ck-toolbar {
  border-radius: .5em .5em 0 0 !important;
}

.ck.ck-balloon-panel.ck-powered-by-balloon {
  display: none !important;
}

.form .field.text textarea, .form .field.select select, .form .field.textarea textarea, .form .field.text input, .form .field.rich .input-container, .form .field.color .input-container, .form .field.combo .input-container {
  box-shadow: 0 .3125em .625em #03266306;
}

.form .field.readonly select, .form .field.readonly textarea, .form .field.readonly input, .form .field.color.readonly .input-container, .form .field.combo.readonly .input-container, .form .field.rich.readonly .input-container {
  cursor: default;
  color: #9fa6b3;
  background-color: #f8fcff;
}

.form .field.file.readonly .input-container label {
  display: none;
}

.form .field.combo.readonly .selection .option {
  cursor: default;
}

.form .field.combo-check.readonly .zone:after, .form .field.combo-select.readonly .zone:after {
  display: none;
}

.form .field.combo-check.readonly .input-container, .form .field.combo-select.readonly .input-container {
  padding-right: 0;
}

.form .field.radio .options .option input:read-only:checked {
  opacity: .4;
  border-color: #a0a0a0;
}

.form .field.radio.readonly .options .option {
  pointer-events: none;
  cursor: default;
}

input.validator {
  opacity: 0;
  pointer-events: none;
  border: none;
  width: 100%;
  height: 100%;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.validation-group {
  transition: border-color .25s;
}

.validation-group.invalid, .form .field.text select:invalid, .form .field.text textarea:invalid, .form .field.textarea textarea:invalid, .form .field.text input:invalid, .form .field.combo.invalid .input-container {
  border-color: #ff656c !important;
}

.form .field.grey > label {
  color: #bbc3d2;
}

.folder {
  pointer-events: none;
  max-height: 0;
  transition: max-height .25s, opacity .25s;
  overflow: hidden;
}

.active > .folder.active-direct-parent-unfold, .folder.active-unfold.active {
  pointer-events: auto;
  max-height: 100vh;
}

.open .folder.open-parent-unfold, .spe-parent.open .folder.open-spe-parent-unfold {
  pointer-events: auto;
  max-height: 50vh;
}

@media only screen and (max-width: 850px) {
  .folder-mobile {
    pointer-events: none;
    max-height: 0;
    transition: max-height .25s, opacity .25s;
    overflow: hidden;
  }

  .folder-mobile.active-unfold-mobile.active {
    pointer-events: auto;
    max-height: 100vh;
  }
}

.open .open-parent-rotated-180, .spe-parent.open .open-spe-parent-rotated-180, .active .active-parent-rotated-180, .spe-parent.active .active-spe-parent-rotated-180 {
  transform: rotate(180deg);
}

.scrollable {
  overflow: hidden auto;
}

.scrollable-x {
  overflow: auto hidden;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 850px) {
  .scrollable-x-mobile {
    overflow: auto hidden;
  }
}

.emoji {
  font-family: EmojiSymbols;
}

.t-huge {
  font-size: 400%;
  line-height: 1.172em;
}

.t-bigger {
  font-size: 200%;
  line-height: 1.172em;
}

.t-big {
  font-size: 175%;
  line-height: 1.1428em;
}

.t-mider {
  font-size: 150%;
  line-height: 1.172em;
}

.t-mid {
  font-size: 125%;
  line-height: 1.172em;
}

.t-std {
  font-size: 112.5%;
  line-height: 1.172em;
}

.t-small {
  font-size: 100%;
  line-height: 1.172em;
}

.t-smaller {
  font-size: 87.5%;
  line-height: 1.172em;
}

.t-smallest {
  font-size: 75%;
  line-height: 1.172em;
}

.underline {
  text-decoration: underline;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.italic {
  font-style: italic;
}

.light {
  font-weight: 100;
}

.medium {
  font-weight: 500;
}

.bold, .active.active-bold {
  font-weight: bold;
}

.bolder {
  font-weight: 750;
}

.boldest {
  font-weight: 900;
}

.line-height-std {
  line-height: 1em;
}

.line-height-classic {
  line-height: 1.1718em;
}

.letter-spacing-label {
  letter-spacing: .08em;
}

.gradient-white-transparent {
  background-color: #0000;
  background-image: linear-gradient(90deg, #fff6, #0000);
}

.gradient-rehausse-transparent {
  background-image: linear-gradient(90deg, #4264eb, #0000);
}

.gradient-purple {
  background-color: #dcaff9;
  background-image: linear-gradient(137deg, #dcaff9, #af6aeb);
}

.gradient-red {
  background-color: #ff959a;
  background-image: linear-gradient(137deg, #ff959a, #fd5b62);
}

.gradient-blue {
  background-color: #a7acfd;
  background-image: linear-gradient(137deg, #a7acfd, #7879ff);
}

.gradient-green {
  background-color: #62f4d1;
  background-image: linear-gradient(137deg, #62f4d1, #3acfaf);
}

.gradient-yellow {
  background-color: #ffe679;
  background-image: linear-gradient(137deg, #ffe679, #fdc844);
}

.gradient-orange {
  background-color: #ffbfa7;
  background-image: linear-gradient(137deg, #ffbfa7, #ff8e6e);
}

.gradient-white-alpha-h {
  background-image: linear-gradient(90deg, #fff, #0000);
}

.gradient-white-alpha-h-inv {
  background-image: linear-gradient(270deg, #fff, #0000);
}

.color-inherit {
  color: inherit;
}

.color-red, .hover-color-red:hover {
  color: #ff656c;
}

.active-color-white.active, .active .active-parent-color-white, .hover-color-white:hover, .selected-color-white.selected, :hover > .hover-direct-parent-color-white, .active > .active-direct-parent-color-white, .color-white {
  color: #fff;
}

.active-color-white-important.active {
  color: #fff !important;
}

.color-black {
  color: #000;
}

.color-bluegrey {
  color: #bbc3d2;
}

.color-mediumgrey {
  color: #68717d;
}

.hover-color-darkblue:hover, .active-color-darkblue.active, .active .active-parent-color-darkblue, .active > .active-direct-parent-color-darkblue, .spe-parent.active .active-spe-parent-color-darkblue, .color-darkblue {
  color: #020b59;
}

.hover-color-rehausse:hover, .active-color-rehausse.active, .selected-color-rehausse.selected, .active .active-parent-color-rehausse, .active > .active-direct-parent-color-rehausse, :hover > .hover-direct-parent-color-rehausse, .color-rehausse {
  color: #2b50e8;
}

.color-valid {
  color: #fff;
  background-color: #74d8bc !important;
}

.color-wrong {
  color: #fff;
  background-color: #ff656c !important;
}

.color-progress-red {
  color: #fb6962;
}

.color-progress-grey {
  color: #d9d9d9;
}

.color-progress-yellow {
  color: #fcd92f;
}

.color-progress-green {
  color: #4fcb83;
}

.color-namegrey {
  color: #43484f;
}

.bg-color-backgrey {
  background-color: #f5f5f5;
  background-image: none;
}

.selected-bg-color-rehausse.selected, .active-bg-color-rehausse.active, .active .active-parent-bg-color-rehausse, .active > .active-direct-parent-bg-color-rehausse, .spe-parent.active .active-spe-parent-bg-color-rehausse, .bg-color-rehausse {
  background-color: #2b50e8;
  background-image: none;
}

.active-bg-color-rehausse-important.active {
  background-color: #2b50e8 !important;
  background-image: none !important;
}

.active-bg-color-alpharehausse.active, .bg-color-alpharehausse {
  background-color: #2b51e826;
  background-image: none;
}

.active .active-parent-bg-color-lightrehausse, .active > .active-direct-parent-bg-color-lightrehausse, .active-bg-color-lightrehausse.active, .bg-color-lightrehausse {
  background-color: #d9e0fb;
  background-image: none;
}

.bg-color-lighterrehausse {
  background-color: #e5eeff;
  background-image: none;
}

.bg-color-lightestrehausse {
  background-color: #f9faff;
  background-image: none;
}

.bg-color-darkgrey {
  background-color: #d7dbe4;
  background-image: none;
}

.active .active-parent-bg-color-darkblue, .active > .active-direct-parent-bg-color-darkblue, .bg-color-darkblue {
  background-color: #020b59;
  background-image: none;
}

.bg-color-alpha-green {
  background-color: #01b3c699;
}

.active .active-parent-bg-color-white, .active-bg-color-white.active, .selected-bg-color-white.selected, .hover-bg-color-white:hover, .bg-color-white {
  background-color: #fff;
  background-image: none;
}

.bg-color-highlight {
  background-color: #fffae4;
  background-image: none;
}

.hover-bg-color-red:hover, .bg-color-red {
  background-color: #ffafb3;
  background-image: none;
}

.hover-bg-color-green:hover, .bg-color-green {
  background-color: #01b3c6;
  background-image: none;
}

.hover-bg-color-darkgreen:hover, .bg-color-darkgreen {
  background-color: #0a424a;
  background-image: none;
}

.hover-bg-color-lightgreen:hover, .bg-color-lightgreen {
  background-color: #d9f4f7;
  background-image: none;
}

.hover-bg-color-lightergreen:hover, .bg-color-lightergreen {
  background-color: #ebf9fb;
  background-image: none;
}

.hover-bg-color-blue:hover, .bg-color-blue {
  background-color: #7879ff;
  background-image: none;
}

.hover-bg-color-purple:hover, .bg-color-purple {
  background-color: #af6aeb;
  background-image: none;
}

.hover-bg-color-yellow:hover, .bg-color-yellow {
  background-color: #fcd92f;
  background-image: none;
}

.hover-bg-color-orange:hover, .bg-color-orange {
  background-color: #ff8e6e;
  background-image: none;
}

:hover > .hover-direct-parent-bg-color-lightestgrey, .bg-color-lightestgrey {
  background-color: #f7f7f7;
  background-image: none;
}

.selected .selected-parent-bg-color-lightergrey, .selected-bg-color-lightergrey.selected, .active-bg-color-lightergrey.active, .hover-bg-color-lightergrey:hover, .active .active-parent-bg-color-lightergrey, .bg-color-lightergrey {
  background-color: #f0f3fd;
  background-image: none;
}

.hover-bg-color-lightgrey:hover, .bg-color-lightgrey {
  background-color: #eceef5;
  background-image: none;
}

.hover-bg-color-bluegrey:hover, .bg-color-bluegrey {
  background-color: #bbc3d2;
  background-image: none;
}

.hover-bg-color-mediumgrey:hover, .bg-color-mediumgrey {
  background-color: #68717d;
  background-image: none;
}

.bg-color-valid {
  background-color: #56d47c;
  background-image: none;
}

.bg-color-progress-green {
  background-color: #4fcb83;
  background-image: none;
}

.bg-color-progress-yellow {
  background-color: #fcd92f;
  background-image: none;
}

.bg-color-progress-red {
  background-color: #fb6962;
  background-image: none;
}

.bg-color-progress-grey {
  background-color: #d9d9d9;
  background-image: none;
}

.border-color-green {
  border-color: #01b3c6 !important;
}

.border-color-darkblue {
  border-color: #020b59 !important;
}

.border-color-bluegrey {
  border-color: #bbc3d2 !important;
}

.border-color-lightgrey {
  border-color: #e5e5e5 !important;
}

.border-color-grey {
  border-color: #dde2e5 !important;
}

.border-color-red {
  border-color: #ff656c !important;
}

.border-color-white {
  border-color: #fff !important;
}

.selected-border-color-rehausse.selected, .hover-border-color-rehausse:hover, :hover > .hover-direct-parent-border-color-rehausse, .border-color-rehausse {
  border-color: #2b50e8 !important;
}

.fill-none svg path {
  fill: none !important;
}

.stroke-color-white svg path {
  stroke: #fff !important;
}

.fill-color-menugrey svg path, .fill-color-bluegrey svg path {
  fill: #bbc3d2 !important;
}

.fill-color-darkblue svg path {
  fill: #020b59 !important;
}

.active .active-parent-fill-color-rehausse svg path, .fill-color-rehausse svg path, :hover > .hover-direct-parent-fill-color-rehausse svg path {
  fill: #2b50e8 !important;
}

.fill-color-black svg path {
  fill: #000 !important;
}

.fill-color-mediumgrey svg path {
  fill: #68717d !important;
}

.active .active-parent-fill-color-white svg path, .fill-color-white svg path, .hover-fill-color-white:hover svg path {
  fill: #fff !important;
}

.fill-color-rehausse svg path, :hover > .hover-direct-parent-fill-color-rehausse svg path, .hover-fill-color-rehausse:hover svg path {
  fill: #2b50e8 !important;
}

.fill-color-valid svg path {
  fill: #4fcb83 !important;
}

.fill-color-invalid svg path {
  fill: #fb6962 !important;
}

.hover-stroke-color-darkgreen:hover svg path {
  stroke: #0a424a;
}

@media only screen and (max-width: 850px) {
  .color-darkblue-mobile {
    color: #020b59;
  }

  .spe-parent.active .active-spe-parent-color-rehausse-mobile {
    color: #2b50e8;
  }

  .bg-color-lightestrehausse-mobile {
    background-color: #f9faff;
    background-image: none;
  }

  .spe-parent.active .active-spe-parent-bg-color-lightrehausse-mobile {
    background-color: #d9e0fb;
    background-image: none;
  }

  .selected .selected-parent-bg-color-alpha-mobile, :hover > .hover-direct-parent-bg-color-alpha-mobile {
    background-color: #0000;
    background-image: none;
  }

  .selected-bg-color-lightergrey-mobile.selected {
    background-color: #f0f3fd;
    background-image: none;
  }
}

.blur {
  filter: blur(50px);
}

.transition-full-color {
  transition: color .25s, background-color .25s, border-color .25s;
}

.transition-full-color svg path {
  transition: fill .25s;
}

.transition-t-color {
  transition: color .25s;
}

.transition-bg-color {
  transition: background-color .25s;
}

.transition-border-color {
  transition: border-color .25s;
}

.transition-fill-color svg path {
  transition: fill .25s;
}

.shadow-modal {
  box-shadow: 0 0 5em #0000001a;
}

.rounded {
  border-radius: 50%;
}

.rounded-0-25em {
  border-radius: .25em;
}

.rounded-0-5em {
  border-radius: .5em;
}

.rounded-left-0-5em {
  border-top-left-radius: .5em;
  border-bottom-left-radius: .5em;
}

.rounded-top-left-0-5em {
  border-top-left-radius: .5em;
}

.rounded-right-0-5em {
  border-top-right-radius: .5em;
  border-bottom-right-radius: .5em;
}

.rounded-top-right-0-5em {
  border-top-right-radius: .5em;
}

.rounded-1em {
  border-radius: 1em;
}

.border-separate {
  border-collapse: separate;
}

@media only screen and (max-width: 850px) {
  .rounded-0-5em-mobile {
    border-radius: .5em;
  }
}

.border-1 {
  border: 1px solid #0000;
}

.border-2 {
  border: 2px solid #0000;
}

.active > .active-direct-parent-border-right-2, .border-right-2 {
  border-right: 2px solid #0000;
}

.border-right-1 {
  border-right: 1px solid #0000;
}

.active > .active-direct-parent-border-top-1, .border-top-1 {
  border-top: 1px solid #0000;
}

.active > .active-direct-parent-border-top-2, .border-top-2 {
  border-top: 2px solid #0000;
}

.active > .active-direct-parent-border-left-2, .border-left-2 {
  border-left: 2px solid #0000;
}

.border-left-1 {
  border-left: 1px solid #0000;
}

.active > .active-direct-parent-border-bot-2, .border-bot-2 {
  border-bottom: 2px solid #0000;
}

.border-bot-1 {
  border-bottom: 1px solid #0000;
}

.border-dashed {
  border-style: dashed;
}

@media only screen and (max-width: 850px) {
  .border-none-mobile {
    border: none !important;
  }

  .active-border-top-2-mobile.active {
    border-top: 2px solid #0000;
  }

  .active-border-bot-2-mobile.active {
    border-bottom: 2px solid #0000;
  }

  .active-border-right-2-mobile.active {
    border-right: 2px solid #0000;
  }
}

.transition-opacity {
  transition: opacity .25s;
}

.active > .active-direct-parent-opacity-0, .opacity-0 {
  opacity: 0;
}

.opacity-20 {
  opacity: .2;
}

.opacity-40 {
  opacity: .4;
}

.opacity-60 {
  opacity: .6;
}

.opacity-80 {
  opacity: .8;
}

.opacity-100, .active > .active-direct-parent-opacity-100, .active .active-parent-opacity-100, .active-opacity-100.active {
  opacity: 1;
}

.active .active-parent-hidden, .hidden {
  opacity: 0;
  pointer-events: none;
  width: 0;
  min-width: 0;
  height: 0;
  min-height: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: absolute;
  overflow: hidden;
}

.active .active-parent-visible {
  pointer-events: all;
  width: auto;
  min-width: auto;
  height: auto;
}

.empty-hidden:empty {
  display: none;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y {
  overflow-y: auto;
}

@media only screen and (max-width: 850px) {
  .opacity-0-mobile {
    opacity: 0;
  }

  .open .open-parent-opacity-100-mobile {
    opacity: 1;
  }

  .overflow-hidden-mobile {
    overflow: hidden;
  }

  .hidden-mobile {
    pointer-events: none;
    width: 0;
    min-width: 0;
    height: 0;
    min-height: 0;
    margin: 0;
    padding: 0;
    display: block;
    position: absolute;
    overflow: hidden;
  }

  .active-visible-mobile.active {
    overflow: initial;
    pointer-events: all;
    width: auto;
    min-width: auto;
    height: auto;
    position: relative;
  }
}

.transition-transform {
  transition: transform .25s, -webkit-transform .25s, opacity .25s;
  -webkit-transition: transform .25s, opacity .25s, -webkit-transform .25s, opacity .25s;
}

.translate-x-100 {
  transform: translateX(100%);
}

.active > .active-direct-parent-translate-x-0 {
  transform: translateX(0);
}

@media only screen and (max-width: 850px) {
  .translate-x-100-mobile {
    transform: translateX(100%);
  }

  .translate-x--100-mobile {
    transform: translateX(-100%);
  }

  .open .open-parent-translate-x-0-mobile {
    transform: translateX(0);
  }

  .translate-y--100-mobile {
    transform: translateY(-100%);
  }

  .open .open-parent-translate-y-0-mobile {
    transform: translateY(0);
  }
}

.transition-duration-0-5 {
  transition-duration: .5s !important;
}

.transition-duration-1 {
  transition-duration: 1s !important;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes pop-in {
  0% {
    opacity: 0;
    transform: scale(.8);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes up-rotate {
  0% {
    animation-timing-function: cubic-bezier(.16, -.88, .97, .53);
    transform: translateY(0);
  }

  30% {
    transform-origin: center;
    animation-timing-function: cubic-bezier(.34, 1.56, .64, 1);
    transform: translateY(-10px);
  }

  100% {
    transform-origin: center;
    transform: translateY(-10px)rotate(45deg)scale(.9);
  }
}

@keyframes down-rotate {
  0% {
    animation-timing-function: cubic-bezier(.16, -.88, .97, .53);
    transform: translateY(0);
  }

  30% {
    transform-origin: center;
    animation-timing-function: cubic-bezier(.34, 1.56, .64, 1);
    transform: translateY(10px);
  }

  100% {
    transform-origin: center;
    transform: translateY(10px)rotate(-45deg)scale(.9);
  }
}

@keyframes hide {
  29% {
    opacity: 1;
  }

  30% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.grid-container {
  display: grid;
}

.grid {
  display: -ms-grid;
  -ms-grid-gap: 1em 1em;
  grid-gap: 1em 1em;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: 1fr 1fr 1fr 1fr 1fr;
  grid-auto-rows: minmax(0, 1fr);
  grid-auto-columns: minmax(0, 1fr);
  width: 100%;
  height: 100%;
  display: grid;
}

.classic-content {
  line-height: 1.25em;
}

.classic-content a {
  color: inherit;
}

.classic-content a:hover {
  opacity: .6;
}

.classic-content p + p {
  margin-top: 1.5em;
}

.classic-content * + ul {
  margin-top: .5em;
}

.classic-content ul, .classic-content ol {
  margin: .5em 0;
  list-style: none;
}

.classic-content ol {
  counter-reset: item;
}

.classic-content ul li, .classic-content ol li {
  counter-increment: item;
  padding-left: .25em;
}

.classic-content ul li:before {
  content: "•";
  padding-right: .5em;
  font-size: 110%;
  font-weight: 900;
  display: inline-block;
}

.classic-content ol li:before {
  content: counter(item) ".";
  padding-right: .5em;
  font-size: 110%;
  font-weight: 900;
  display: inline-block;
}

.classic-content ul li + li, .classic-content ol li + li {
  margin-top: .15em;
}

.classic-content ul ul {
  padding-left: .5em;
}

.classic-content ul ul li:before {
  content: "◦";
}

.classic-content ul ul ul li:before {
  content: "•";
}

.classic-content ul ul ul ul li:before {
  content: "◦";
}

.classic-content img {
  max-width: 100%;
  margin: 1em auto;
}

.classic-content .image-style-side {
  float: left;
  width: 50%;
  margin: 1em 1em 1em 0;
}

.classic-content.column-count-2 {
  column-count: 2;
  column-gap: 2em;
}

@media only screen and (max-width: 850px) {
  .classic-content.column-count-2 {
    column-count: 1;
  }
}

/*# sourceMappingURL=login.efaea4cd.css.map */
